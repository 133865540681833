import React, { useEffect } from 'react';
import Demand from './Demand/Demand';
import ShoppingList from './ShoppingList/ShoppingList';
import YourPurchases from './YourPurchases/YourPurchases';
import Overview from './Overview/Overview';
import { TabComponent } from '@components';
import { AppName, TradingAdvisorTitle } from '@utils';
import { useSelector } from 'react-redux';
import { useSearchParams, useParams } from 'react-router-dom';
import { getDemand } from '../../../store/feature/tradingadvisor/tradingAdvisorSlice';
import { useDispatch } from 'react-redux';


const TradingAdvisor = () => {
    const [searchParams] = useSearchParams();
    const productType = searchParams.get('productType'); // Get productType from query params
    const {selectedDemand} = useSelector((state) => state.demand);
    const dispatch = useDispatch();
    const uuid = useParams().uuid;
     

      useEffect(() => {
            if (uuid) {
                dispatch(getDemand(uuid));
            }
        }, [uuid, dispatch]);

     
    
    useEffect(() => {
        document.title = `${AppName} | ${TradingAdvisorTitle}`;
    }, []);

    const theeur = useSelector((state) => state.the.theeur);
    const ttfeur = useSelector((state) => state.ttf.eur);
    const pegeur = useSelector((state) => state.pegnord.pegnordEur);

    // Determine which contract data to use based on productType
    let contractData;
    switch (productType) {
        case 'THE':
            contractData = theeur?.contracts || null;
            break;
        case 'TTF':
            contractData = ttfeur?.contracts || null;
            break;
        case 'PEG':
            contractData = pegeur?.contracts || null;
            break;
        default:
            contractData = theeur?.contracts || null; // Default to THE if not specified
    }

    const tabs = [
        {
            name: 'Shopping List',
            content: <ShoppingList contract={contractData} />
        },
        {
            name: 'Your Purchases',
            content: <YourPurchases />
        },
        {
            name: 'Overview',
            content: <Overview contract={contractData} />
        }
    ];

    return <TabComponent tabs={tabs} />;
};

export default TradingAdvisor;

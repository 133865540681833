import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createSlice } from '@reduxjs/toolkit';

// RTK Query API service
export const tradingAdvisorApi = createApi({
  reducerPath: 'tradingAdvisorApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  tagTypes: ['Demands', 'Purchases'],
  endpoints: (builder) => ({
    // Get all demands
    getAllDemands: builder.query({
      query: () => 'demands',
      providesTags: ['Demands']
    }),
    
    // Get single demand
    getDemand: builder.query({
      query: (uuid) => `demands/${uuid}`,
      providesTags: (result, error, uuid) => [{ type: 'Demands', id: uuid }]
    }),
    
    // Upload demand
    uploadDemand: builder.mutation({
      query: (demandData) => ({
        url: 'import_demand',
        method: 'POST',
        body: demandData,
        formData: true,
      }),
      invalidatesTags: ['Demands']
    }),
    
    // Save shopping list
    saveShoppingList: builder.mutation({
      query: (shoppingListItem) => ({
        url: 'shoppinglist',
        method: 'POST',
        body: shoppingListItem,
      }),
      invalidatesTags: ['Demands']
    }),
    
    // Get purchases
    getPurchases: builder.query({
      query: (uuid) => `shoppinglist_orders?demand_uuid=${uuid}`,
      providesTags: ['Purchases']
    }),
    
    // Delete demand
    deleteDemand: builder.mutation({
      query: (uuid) => ({
        url: `demands/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Demands']
    }),
  }),
});

// Legacy state slice for backward compatibility
const tradingAdvisorSlice = createSlice({
  name: 'demand',
  initialState: {
    demands: [],
    purchases: [],
    selectedDemand: null,
    isUploading: false,
    isFetching: false,
    isFetchingSingle: false,
    isDeleting: false,
    lastPurchaseTime: Date.now(),
    error: null
  },
  reducers: {
    triggerPurchasesRefetch: (state) => {
      state.lastPurchaseTime = Date.now();
    }
  },
});

// Export the hooks
export const {
  useGetAllDemandsQuery,
  useGetDemandQuery,
  useUploadDemandMutation,
  useSaveShoppingListMutation,
  useGetPurchasesQuery,
  useDeleteDemandMutation
} = tradingAdvisorApi;

// Export legacy action creators for backward compatibility
export const { triggerPurchasesRefetch } = tradingAdvisorSlice.actions;

// Export the old action types as thunks for backward compatibility
export const getAllDemands = () => (dispatch) => {
  // No action needed - components should use the hook instead
  console.warn('getAllDemands thunk is deprecated. Use useGetAllDemandsQuery hook instead.');
};

export const getDemand = (uuid) => (dispatch) => {
  console.warn('getDemand thunk is deprecated. Use useGetDemandQuery hook instead.');
};

export const uploadDemand = (data) => (dispatch) => {
  console.warn('uploadDemand thunk is deprecated. Use useUploadDemandMutation hook instead.');
};

export const saveShoppingList = (data) => (dispatch) => {
  console.warn('saveShoppingList thunk is deprecated. Use useSaveShoppingListMutation hook instead.');
};

export const getPurchases = (uuid) => (dispatch) => {
  console.warn('getPurchases thunk is deprecated. Use useGetPurchasesQuery hook instead.');
};

export const deleteDemand = (uuid) => (dispatch) => {
  console.warn('deleteDemand thunk is deprecated. Use useDeleteDemandMutation hook instead.');
};

// For store setup
export const tradingAdvisorReducer = tradingAdvisorSlice.reducer;
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CommonContainer, InfoModal } from '@components'
import { Box, Button, Modal, Typography } from '@mui/material';
import { apiGetNcgDataPoints, apiGetAnalysisSignal } from '@store';
import TimeSeriesForecasting from './TimeSeriesForecasting';
import AskBollingerBandChart from './AskBollingerBandChart';
// the eur
import { Buy as TheEurBuy } from '../../../pages/gas/the/eur/Buy';
// the chf
import { Buy as TheChfBuy } from '../../../pages/gas/the/chf/Buy';
//ttf eur
import { Buy as TtfEurBuy } from '../../../pages/gas/ttf/eur/Buy';
// peg eur
import { Buy as PegEurBuy } from '../../../pages/gas/pegnord/eur/Buy';
import ForecastingChart from './ForecastingChart';
import AnalysisSignal from './AnalysisSignal';

const Ask = () => {
    const dispatch = useDispatch();
    const { theeur, chf, ncgDataPoints, disableTrading, analysisSignal } = useSelector(state => state.the);
    const { user } = useSelector((state) => state.user);
    const disableTradingStatus = disableTrading?.disable
    const useQuery = () => { return new URLSearchParams(window.location.search); }

    const sym = useQuery().get('sym')
    const currency = useQuery().get('currency')
    const product = useQuery().get('product')

    // fetch ncg data points
    useEffect(() => {
        dispatch(apiGetNcgDataPoints({ sym: sym, currency: currency }))
        dispatch(apiGetAnalysisSignal({ sym: sym, product: product }))
    }, [dispatch, sym, currency, product])

    // info modal
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const handleOpenInfoModal = () => { setOpenInfoModal(true); }
    const handleCloseInfoModal = () => { setOpenInfoModal(false); }

    // buy modal
    const [openBuyModal, setOpenBuyModal] = useState(false)
    const handleOpenBuyModal = useCallback(() => { setOpenBuyModal(true) }, [])
    const handleCloseBuyModal = () => setOpenBuyModal(false)

    if (!sym || !currency) {
        return <div>Contract and currency not provided</div>
    }

    let selectedContract = null

    if (currency === 'eur' && theeur?.contracts?.[sym]) {
        selectedContract = theeur?.contracts?.[sym];
    } else if (currency === 'chf' && chf?.contracts?.[sym]) {
        selectedContract = chf?.contracts?.[sym];
    }

    // start of buy sell restriction modal
    // convert user.StartTime and user.EndTime string to hh:mm
    const startTime = user?.StartTime?.split(':').slice(0, 2).join(':')
    const endTime = user?.EndTime?.split(':').slice(0, 2).join(':')
    const lunchStart = user?.LunchStart?.split(':').slice(0, 2).join(':')
    const lunchEnd = user?.LunchEnd?.split(':').slice(0, 2).join(':')

    // convert into berlin time
    // if current time is between startTime and endTime and not between lunchStart and lunchEnd then show modal
    var europeTime = new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" });
    var time = new Date(europeTime);
    var hours = time.getHours();
    var minutes = time.getMinutes();
    // Format current time like startTime and endTime (hh:mm)
    var currentTime = `${hours.toString().padStart(2, '0')}.${minutes.toString().padStart(2, '0')}`;
    // end of buy sell restriction modal

    return (
        <>
            {/* info modal */}
            <Modal open={openInfoModal} onClose={handleCloseInfoModal}>
                <InfoModal
                    handleClose={handleCloseInfoModal}
                    startTime={startTime}
                    endTime={endTime}
                    lunchStart={lunchStart}
                    lunchEnd={lunchEnd}
                />
            </Modal>
            {/* buy modal */}
            <Modal open={openBuyModal} onClose={handleOpenBuyModal}>
                <>
                    {currency === 'eur' && product === 'THE' &&
                        <TheEurBuy
                            handleClose={handleCloseBuyModal}
                            open={openBuyModal}
                            selectedContract={selectedContract}
                            user={user}
                            sym={sym}
                        />
                    }
                    {currency === 'chf' && product === 'THE' &&
                        <TheChfBuy
                            handleClose={handleCloseBuyModal}
                            open={openBuyModal}
                            selectedContract={selectedContract}
                            user={user}
                            sym={sym}
                        />
                    }
                    {currency === 'eur' && product === 'TTF' &&
                        <TtfEurBuy
                            handleClose={handleCloseBuyModal}
                            open={openBuyModal}
                            selectedContract={selectedContract}
                            user={user}
                            sym={sym}
                        />
                    }
                    {currency === 'eur' && product === 'PEG' &&
                        <PegEurBuy
                            handleClose={handleCloseBuyModal}
                            open={openBuyModal}
                            selectedContract={selectedContract}
                            user={user}
                            sym={sym}
                        />
                    }
                </>
            </Modal>
            <CommonContainer sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: '20px',
                            color: '#27485D',
                        }}
                    >
                        {currency === 'eur' && 'EUR'} {currency === 'chf' && 'CHF'} {currency === 'ttfEUR' && 'TTF'} {currency === 'pegEUR' && 'PEG'} - {sym}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '10px'
                        }}
                    >
                        {disableTradingStatus === false && user?.HideBuyButton === false &&
                            <Button
                                variant='outlined'
                                color='primary'
                                sx={{
                                    p: 0,
                                    m: 0,
                                    borderColor: '1px solid #27A9FF',
                                    color: '#27A9FF',
                                    fontSize: '13px',
                                    '&:hover': {
                                        borderColor: '1px solid #27A9FF',
                                        color: 'white',
                                        backgroundColor: '#27A9FF',
                                    },
                                }}
                                onClick={() => {
                                    if (startTime <= currentTime && currentTime <= endTime && (currentTime < lunchStart || currentTime > lunchEnd)) {
                                        handleOpenBuyModal()
                                    } else {
                                        handleOpenInfoModal()
                                    }
                                }}
                            >
                                Buy
                            </Button>
                        }
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '15px',
                    }}
                >
                    <AskBollingerBandChart ncgDataPoints={ncgDataPoints} currency={currency} />
                </Box>
            </CommonContainer>
            <ForecastingChart ncgDataPoints={ncgDataPoints} currency={currency} />
            <TimeSeriesForecasting ncgDataPoints={ncgDataPoints} />
            <AnalysisSignal analysisSignal={analysisSignal} ncgDataPoints={ncgDataPoints} />
        </>
    )
}

export default Ask
import UploadDemand from '../assets/svg/UploadDemand.svg';
import ReceiveTailoredData from '../assets/svg/ReceiveTailoredData.svg';
import CreateEditShoppingList from '../assets/svg/CreateEditShoppingList.svg';

export const demandLandingPageData = [
    {
        id: 1,
        title: 'Upload your demand',
        description: 'Start by downloading the CSV format template provided on this page. Fill it with your trading demand details and upload it to our platform.',
        image: UploadDemand,
    },
    {
        id: 2,
        title: 'Receive tailored data',
        description: 'Once uploaded, our AI advisor analyzes your data and generates tailored trading insights and suggestions.',
        image: ReceiveTailoredData,
    },
    {
        id: 3,
        title: 'Create and edit shopping list',
        description: 'Press the "Generate Shopping List" button to get a customized shopping list suited to your needs. You can edit this list based on your preferences and easily purchase multiple contracts at once.',
        image: CreateEditShoppingList,
    },
];
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CommonContainer, InfoModal } from '@components'
import { Box, Button, Modal, Typography } from '@mui/material';
import { apiGetNcgDataPoints, apiGetAnalysisSignal } from '@store';
import TimeSeriesForecasting from './TimeSeriesForecasting';
import BidBollingerBandChart from './BidBollingerBandChart';

// the eur
import { Sell as TheEurSell } from '../../../pages/gas/the/eur/Sell';
// the chf
import { Sell as TheChfSell } from '../../../pages/gas/the/chf/Sell';

//ttf eur
import { Sell as TtfEurSell } from '../../../pages/gas/ttf/eur/Sell';

// peg eur
import { Sell as PegEurSell } from '../../../pages/gas/pegnord/eur/Sell';
import ForecastingChart from './ForecastingChart';
import AnalysisSignal from './AnalysisSignal';

const Bid = () => {
    const dispatch = useDispatch();
    const { theeur, chf, ncgDataPoints, analysisSignal, disableTrading } = useSelector(state => state.the);
    const { eur } = useSelector(state => state.ttf);
    const { pegnordEur } = useSelector((state) => state.pegnord)
    const { user } = useSelector((state) => state.user);
    const disableTradingStatus = disableTrading?.disable
    const useQuery = () => { return new URLSearchParams(window.location.search); }

    const sym = useQuery().get('sym')
    const currency = useQuery().get('currency')
    const product = useQuery().get('product')

    // fetch ncg data points
    useEffect(() => {
        dispatch(apiGetNcgDataPoints({ sym: sym, currency: currency }))
        dispatch(apiGetAnalysisSignal({ sym: sym, product: product }))
    }, [dispatch, sym, currency, product])

    // info modal
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const handleOpenInfoModal = () => { setOpenInfoModal(true); }
    const handleCloseInfoModal = () => { setOpenInfoModal(false); }

    // sell modal
    const [openSellModal, setOpenSellModal] = useState(false)
    const handleOpenSellModal = useCallback(() => { setOpenSellModal(true) }, [])
    const handleCloseSellModal = useCallback(() => setOpenSellModal(false), [])

    if (!sym || !currency) {
        return <div>Contract and currency not provided</div>
    }

    let selectedContract = null

    if (currency === 'eur' && theeur?.contracts?.[sym]) {
        selectedContract = theeur?.contracts?.[sym];
    } else if (currency === 'chf' && chf?.contracts?.[sym]) {
        selectedContract = chf?.contracts?.[sym];
    }

    // start of buy sell restriction modal
    // convert user.StartTime and user.EndTime string to hh:mm
    const startTime = user?.StartTime?.split(':').slice(0, 2).join(':')
    const endTime = user?.EndTime?.split(':').slice(0, 2).join(':')
    const lunchStart = user?.LunchStart?.split(':').slice(0, 2).join(':')
    const lunchEnd = user?.LunchEnd?.split(':').slice(0, 2).join(':')

    // convert into berlin time
    // if current time is between startTime and endTime and not between lunchStart and lunchEnd then show modal
    var europeTime = new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" });
    var time = new Date(europeTime);
    var hours = time.getHours();
    var minutes = time.getMinutes();
    // Format current time like startTime and endTime (hh:mm)
    var currentTime = `${hours.toString().padStart(2, '0')}.${minutes.toString().padStart(2, '0')}`;
    // end of buy sell restriction modal

    return (
        <>
            {/* info modal */}
            <Modal open={openInfoModal} onClose={handleCloseInfoModal}>
                <InfoModal
                    handleClose={handleCloseInfoModal}
                    startTime={startTime}
                    endTime={endTime}
                    lunchStart={lunchStart}
                    lunchEnd={lunchEnd}
                />
            </Modal>
            {/* sell modal */}
            <Modal open={openSellModal} onClose={handleCloseSellModal}>
                <>
                    {currency === 'eur' && product === 'THE' &&
                        <TheEurSell
                            handleClose={handleCloseSellModal}
                            open={openSellModal}
                            eur={theeur}
                            selectedContract={selectedContract}
                            user={user}
                            sym={sym}
                        />
                    }
                    {currency === 'chf' && product === 'THE' &&
                        <TheChfSell
                            handleClose={handleCloseSellModal}
                            open={openSellModal}
                            chf={chf}
                            selectedContract={selectedContract}
                            user={user}
                            sym={sym}
                        />
                    }
                    {currency === 'eur' && product === 'TTF' &&
                        <TtfEurSell
                            handleClose={handleCloseSellModal}
                            open={openSellModal}
                            eur={eur}
                            selectedContract={selectedContract}
                            user={user}
                            sym={sym}
                        />
                    }
                    {currency === 'eur' && product === 'PEG' &&
                        <PegEurSell
                            handleClose={handleCloseSellModal}
                            open={openSellModal}
                            pegnordEur={pegnordEur}
                            selectedContract={selectedContract}
                            user={user}
                            sym={sym}
                        />
                    }
                </>
            </Modal>
            <CommonContainer sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: '20px',
                            color: '#27485D',
                        }}
                    >
                        {currency === 'eur' && 'EUR'} {currency === 'chf' && 'CHF'} {currency === 'ttfEUR' && 'TTF'} {currency === 'pegEUR' && 'PEG'} - {sym}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '10px'
                        }}
                    >
                        {disableTradingStatus === false && user?.HideSellButton === false &&
                            <Button
                                variant='outlined'
                                color='error'
                                sx={{
                                    p: 0,
                                    m: 0,
                                    borderColor: '1px solid #EB6969',
                                    color: '#EB6969',
                                    fontSize: '13px',
                                    '&:hover': {
                                        borderColor: '1px solid #EB6969',
                                        color: 'white',
                                        backgroundColor: '#EB6969',
                                    },
                                }}
                                onClick={() => {
                                    if (startTime <= currentTime && currentTime <= endTime && (currentTime < lunchStart || currentTime > lunchEnd)) {
                                        handleOpenSellModal()
                                    } else {
                                        handleOpenInfoModal()
                                    }
                                }}
                            >
                                Sell
                            </Button>
                        }
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '15px',
                    }}
                >
                    <BidBollingerBandChart ncgDataPoints={ncgDataPoints} currency={currency} />
                </Box>
            </CommonContainer>
            <ForecastingChart ncgDataPoints={ncgDataPoints} currency={currency} />
            <TimeSeriesForecasting ncgDataPoints={ncgDataPoints} />
            <AnalysisSignal analysisSignal={analysisSignal} ncgDataPoints={ncgDataPoints} />
        </>
    )
}

export default Bid
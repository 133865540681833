import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Typography, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UploadDemandModal from './UploadDemandModal';
import { getAllDemands } from '@store/feature/tradingadvisor/tradingAdvisorSlice';
import LandingPage from './LandingPage';
import GasDemandList from './GasDemandList';
import InfoModal from './InfoModal';
import CSVTemplate from './CSVTemplate';
import TableSkeleton from '../Skeleton';

const Demand = ({ setValue }) => {
    const dispatch = useDispatch();
    const { demands, isFetching } = useSelector((state) => state.demand);
    console.log("🚀 ~ Demand ~ demands:", demands)
    const [uploadModal, setUploadModal] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [csvTemplateModal, setCsvTemplateModal] = useState(false);

    useEffect(() => {
        dispatch(getAllDemands());
    }, [dispatch]);

    const handleOpenUploadModal = () => {
        setUploadModal(true);
    };

    const handleCloseUploadModal = () => {
        setUploadModal(false);
    };

    const handleOpenInfoModal = () => {
        setInfoModal(true);
    }

    const handleCloseInfoModal = () => {
        setInfoModal(false);
    }

    const handleOpenCsvTemplateModal = () => {
        setCsvTemplateModal(true);
    }

    const handleCloseCsvTemplateModal = () => {
        setCsvTemplateModal(false);
    }


    return (
        <Box className="flex flex-col gap-4">
            <Modal open={uploadModal} onClose={handleCloseUploadModal}>
                <UploadDemandModal handleClose={handleCloseUploadModal} />
            </Modal>
            <Modal open={infoModal} onClose={handleCloseInfoModal}>
            <InfoModal 
                isOpen={infoModal} 
                onClose={handleCloseInfoModal} 
            />
            </Modal>
            <Modal open={csvTemplateModal} onClose={handleCloseCsvTemplateModal}>
                <CSVTemplate 
                isOpen={csvTemplateModal}
                onClose={handleCloseCsvTemplateModal}
                />
            </Modal>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                    mb: 2
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '3px',
                        alignItems: 'center',
                        ":hover": {
                            cursor: 'pointer'
                        }
                    }}
                    onClick={handleOpenInfoModal}
                >
                    <InfoOutlinedIcon
                        sx={{
                            fontSize: '16px',
                            p: 0,
                            m: 0
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: '13px',
                            p: 0,
                            m: 0
                        }}
                    >
                        More Info
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 4,
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: '#27A9FF',
                            p: 0,
                            m: 0
                        }}
                        onClick={handleOpenCsvTemplateModal}
                    >
                        Get CSV Template
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            borderColor: '1px solid #27A9FF',
                            backgroundColor: '#27A9FF',
                            color: 'white',
                            fontSize: '13px',
                            '&:hover': {
                                borderColor: '1px solid #27A9FF',
                                color: 'white',
                                backgroundColor: '#27A9FF'
                            }
                        }}
                        onClick={handleOpenUploadModal}
                    >
                        Upload Demand
                    </Button>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                {demands.length > 0 ? (
                    <GasDemandList demands={demands} isFetching={isFetching} />
                ) :
                (isFetching ? (
                  <TableSkeleton rows={5} columns={5} />
                )
                : (
                    <LandingPage />
                ))}
            </Box>
        </Box>
    );
};

export default Demand;

import React, { useState, useEffect, useCallback } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {
    Button,
    TextField,
    Box,
    Typography,
    Stack,
    Snackbar,
    Alert,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton,
    Modal,
    Tooltip
} from '@mui/material';
import { Add, Save, Remove } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
    saveShoppingList,
    getAllDemands,
    getDemand
} from '@store/feature/tradingadvisor/tradingAdvisorSlice';
import Buy from './Buy';

// Add this helper function to convert to Float32 format
const toFloat32 = (value) => {
    // Handle empty/null/undefined values
    if (value === '' || value === null || value === undefined) {
        return '';
    }

    // Convert to number and ensure float32 precision
    const float32 = new Float32Array([Number(value)])[0];

    // Return the float32 value
    return float32;
};

const HedgingTable = ({ shoppingList, productType, contract, isLoading }) => {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { selectedDemand } = useSelector((state) => state.demand);

    const [data, setData] = useState(selectedDemand?.shopping_list || []);

    const [expandedRows, setExpandedRows] = useState({});
    const [editingRows, setEditingRows] = useState({}); // new state for edit mode
    const [errorMessage, setErrorMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);
    const [buyVolume, setBuyVolume] = useState(0);
    const [shoppingItemUUID, setShoppingItemUUID] = useState('');
    const [hedgingItemUUID, setHedgingItemUUID] = useState('');

    const [openBuyModal, setOpenBuyModal] = useState(false);

    const handleOpenBuyModal = useCallback(
        (shoppingUUID, hedgingUUID, selectedRowContract, volume) => {
            setShoppingItemUUID(shoppingUUID);
            setHedgingItemUUID(hedgingUUID);
            setBuyVolume(volume);
            // Use contract (prop) to get contract details instead of theeur.contracts
            const contractKey = selectedRowContract;
            if (contract && Object.keys(contract).includes(contractKey)) {
                const selectedContractData = {
                    key: contractKey,
                    ...contract[contractKey]
                };
                setSelectedContract(selectedContractData);
                setOpenBuyModal(true);
            } else {
                alert('Selected contract not found. Please try again.');
            }
        },
        [contract]
    );
    const handleCloseBuyModal = () => setOpenBuyModal(false);

    // const strategies = ['Max Buy', 'Min Buy', 'Trailing Stop'];

    // New useEffect to update local data when shoppingList prop changes
    useEffect(() => {
        setData(shoppingList);
        console.log('Shopping List Changed !!');
    }, [shoppingList]);

    // Update data when selectedDemand changes
    // useEffect(() => {
    //     if (selectedDemand?.shopping_list) {
    //       setData(selectedDemand.shopping_list);
    //       console.log("Selected Demand or Shopping List Changed !!");
    //     }
    //   }, [selectedDemand]);

    const handleDetailChange = (rowId, detailIndex, field, value) => {
        setExpandedRows((prev) => {
            const newDetails = [...(prev[rowId] || [])];
            newDetails[detailIndex] = {
                ...newDetails[detailIndex],
                [field]: value
            };
            return { ...prev, [rowId]: newDetails };
        });
    };

    const handleAddDetail = (rowId) => {
        setExpandedRows((prev) => ({
            ...prev,
            [rowId]: [...(prev[rowId] || []), {}]
        }));
    };

    const handleRemoveDetail = (rowId, detailIndex) => {
        // Get the detail
        const detail = expandedRows[rowId]?.[detailIndex];

        // Check if it has been bought
        if (detail && detail.volume_bought > 0) {
            setErrorMessage('Cannot remove an item that has been purchased');
            return;
        }

        // Proceed with removal if not bought
        setExpandedRows((prev) => {
            const newDetails = [...prev[rowId]];
            newDetails.splice(detailIndex, 1);
            return { ...prev, [rowId]: newDetails };
        });
    };

    const validateVolumes = (rowId, details) => {
        const row = data.find((r) => r.uuid === rowId);
        const totalInputVolume = details.reduce(
            (sum, detail) => sum + (parseFloat(detail.volume) || 0),
            0
        );

        if (totalInputVolume > row.volume) {
            setErrorMessage(
                `Total volume (${totalInputVolume}) exceeds available volume (${row.volume})`
            );
            return false;
        }
        return true;
    };

    // Validate hedging dates for each detail
    const hedgingDateValidation = (rowId, details) => {
        const invalidDates = details.some(
            (detail) =>
                new Date(detail.hedgingStart) > new Date(detail.hedgingEnd)
        );

        if (invalidDates) {
            setErrorMessage('Hedging start date must be before end date');
            return false;
        }
        return true;
    };

    const handleEditRow = (row) => {
        // Pre-populate the expandedRows with existing hedging_data from row
        setExpandedRows((prev) => ({
            ...prev,
            [row.original.uuid]: row.original.hedging_data.map((detail) => ({
                volume: detail.volume,
                hedgingStart: detail.hedging_start_str,
                hedgingEnd: detail.hedging_end_str,
                strategy: detail.strategy
            }))
        }));
        setEditingRows((prev) => ({ ...prev, [row.original.uuid]: true }));
    };

    const handleSaveRow = async (rowId) => {
        try {
            const details = expandedRows[rowId] || [];

            const row = data.find((r) => r.uuid === rowId);

            // Validate required fields
            const invalidDetails = details.some(
                (detail) =>
                    !detail.volume ||
                    !detail.hedgingStart ||
                    !detail.hedgingEnd ||
                    !detail.strategy
            );
            if (invalidDetails) {
                setErrorMessage('Please fill in all required fields');
                return;
            }

            // Validate volumes
            if (!validateVolumes(rowId, details)) {
                return;
            }

            // Validate hedging dates
            if (!hedgingDateValidation(rowId, details)) {
                return;
            }

            // Validate hedging dates
            if (!hedgingDateValidation(rowId, details)) {
                return;
            }

            // Get the existing hedging_data from shoppingList (data) for this row and return the prepared hedging data
            const originalHedgingData =
                data.find((item) => item.uuid === rowId)?.hedging_data || [];

            const hedgingData = details.map((detail, index) => {
                // Try to retrieve uuid from expandedRows first
                const existingUUID = expandedRows[rowId]?.[index]?.uuid || '';
                // If not there, check original data's hedging_data
                const originalUUID = originalHedgingData[index]?.uuid || '';

                return {
                    sequence: index + 1,
                    volume: parseFloat(detail.volume),
                    hedging_start_str: detail.hedgingStart,
                    hedging_end_str: detail.hedgingEnd,
                    strategy: detail.strategy,
                    // Use the uuid from original data if available; otherwise, any found in expandedRows
                    uuid: originalUUID || existingUUID,
                    // Set flag to false when the uuid exists, else true
                    flag: originalUUID ? false : existingUUID ? false : true
                };
            });

            // Prepare the data to be sent in the request body
            const shoppingListPayload = {
                uuid: rowId,
                demand_uuid: row.demand_uuid, // Assuming row contains `demand_uuid`
                hedging_data: hedgingData
            };

            // Send the POST request
            const response = await dispatch(
                saveShoppingList(shoppingListPayload)
            );

            if (response?.meta?.requestStatus === 'fulfilled') {
                console.log('Selected Demand:', selectedDemand);
                dispatch(getDemand(shoppingListPayload.demand_uuid));
                dispatch(getAllDemands());
                // Turn off edit mode on save success
                setEditingRows((prev) => ({ ...prev, [rowId]: false }));
            }

            setSnackbarOpen(true);
            setErrorMessage('');
        } catch (error) {
            setErrorMessage(error.message || 'Error saving details');
        }
    };

    // New handler for canceling row edit and resetting expandedRows
    const handleCancelRowEdit = (rowId) => {
        const row = data.find((r) => r.uuid === rowId);
        if (row) {
            setExpandedRows((prev) => ({
                ...prev,
                [rowId]: row.hedging_data.map((detail) => ({
                    volume: detail.volume,
                    hedgingStart: detail.hedging_start_str,
                    hedgingEnd: detail.hedging_end_str,
                    strategy: detail.strategy
                }))
            }));
        }
        setEditingRows((prev) => ({ ...prev, [rowId]: false }));
    };

    // New handler to ensure editing mode and then add a detail
    const handleEnsureEditingAndAddDetail = (row) => {
        if (!editingRows[row.original.uuid]) {
            handleEditRow(row);
            setTimeout(() => {
                handleAddDetail(row.original.uuid);
            }, 0);
        } else {
            handleAddDetail(row.original.uuid);
        }
    };

    // New: helper to update strategy field with an object
    const handleStrategyChange = (rowId, detailIndex, value) => {
        setExpandedRows((prev) => {
            const newDetails = [...(prev[rowId] || [])];
            newDetails[detailIndex] = {
                ...newDetails[detailIndex],
                strategy:
                    value === 'trailingstop'
                        ? {
                              name: 'trailingstop',
                              trailing_stop_strategy: {
                                  ceiling_margin: '',
                                  floor_margin: ''
                              }
                          }
                        : { name: value }
            };
            return { ...prev, [rowId]: newDetails };
        });
    };

    // New: helper to update trailing stop margins inside strategy
    const handleTrailingMarginChange = (
        rowId,
        detailIndex,
        marginType,
        value
    ) => {
        // Convert to Float32 only for non-empty values
        const float32Value = value === '' ? '' : toFloat32(value);

        setExpandedRows((prev) => {
            const newDetails = [...(prev[rowId] || [])];

            // Safe check for undefined strategy
            if (!newDetails[detailIndex]?.strategy) {
                newDetails[detailIndex] = {
                    ...newDetails[detailIndex],
                    strategy: {
                        name: 'trailingstop',
                        trailing_stop_strategy: {}
                    }
                };
            }

            // Safe check for trailing_stop_strategy
            if (!newDetails[detailIndex].strategy.trailing_stop_strategy) {
                newDetails[detailIndex].strategy.trailing_stop_strategy = {};
            }

            const currentStrategy = newDetails[detailIndex].strategy;

            if (currentStrategy && currentStrategy.name === 'trailingstop') {
                newDetails[detailIndex].strategy = {
                    ...currentStrategy,
                    trailing_stop_strategy: {
                        ...currentStrategy.trailing_stop_strategy,
                        [marginType]: float32Value
                    }
                };
            }

            return { ...prev, [rowId]: newDetails };
        });
    };

    const columns = [
        {
            accessorKey: 'contract',
            header: 'Contract',
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        color: '#27A9FF',
                        fontWeight: 'bold',
                        fontSize: '14px'
                    }}
                >
                    {row.original.contract}
                </Typography>
            )
        },
        {
            accessorKey: 'product',
            header: 'Product',
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        color: 'black',

                        fontSize: '14px'
                    }}
                >
                    {row.original.product}
                </Typography>
            )
        },
        {
            accessorKey: 'volume',
            header: 'Volume (MWh/h)',
            Cell: ({ row }) => (
                <Typography
                    sx={{ color: 'teal', fontWeight: 'bold', fontSize: '14px' }}
                >
                    {row.original.volume}
                </Typography>
            )
        },
        {
            accessorKey: 'price',
            header: 'Price in EUR',
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        color: '#27A9FF',
                        fontWeight: 'bold',
                        fontSize: '14px'
                    }}
                >
                    {contract && contract[row.original.contract]
                        ? contract[row.original.contract].ask
                        : 'No price available'}
                </Typography>
            )
        },
        {
            accessorKey: 'signal',
            header: 'Current-Signal',
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        color: '#27A9FF',
                        fontWeight: 'bold',
                        fontSize: '14px'
                    }}
                >
                    {row.original.signal}
                    {/* Buy, Sell, Hold */}
                </Typography>
            )
        }
    ];

    return (
        <Box className="w-full">
            <Modal open={openBuyModal} onClose={handleCloseBuyModal}>
                <Buy
                    handleClose={handleCloseBuyModal}
                    open={openBuyModal}
                    selectedContract={selectedContract}
                    user={user}
                    productTypeMarket={productType}
                    buyVolume={buyVolume}
                    shoppingItemUUID={shoppingItemUUID}
                    hedgingItemUUID={hedgingItemUUID}
                />
            </Modal>
            <MaterialReactTable
                columns={columns}
                data={data}
                enableExpanding
                enableSorting={false}
                enableColumnActions={false}
                initialState={{
                    pagination: {
                        pageSize: 50, // Set initial page size to 50
                        pageIndex: 0 // Start at first page
                    },
                    // expanded: true,
                    density: 'compact'
                }}
                state={{ isLoading: isLoading }}
                renderTopToolbarCustomActions={() => (
                    <Typography
                        variant="h6"
                        color="#245372"
                        sx={{ fontWeight: 'bold', ml: 2 }}
                    >
                        Shopping List
                    </Typography>
                )}
                renderDetailPanel={({ row }) => (
                    <Box className="px-4 py-1">
                        {row.original.hedging_data.length > 0 &&
                        !editingRows[row.original.uuid] ? (
                            <>
                                <Stack spacing={0.5}>
                                    <div className="border border-gray-200 rounded overflow-hidden">
                                        {/* Single header row for all items */}
                                        <div className="grid grid-cols-9 bg-gray-50 border-b border-gray-200">
                                            <div className="p-2 font-bold text-xs text-gray-700 border-r border-gray-200">
                                                Volume
                                            </div>
                                            <div className="p-2 font-bold text-xs text-gray-700 border-r border-gray-200">
                                                Strategy
                                            </div>
                                            <div className="p-2 font-bold text-xs text-gray-700 border-r border-gray-200">
                                                Hedging Start
                                            </div>
                                            <div className="p-2 font-bold text-xs text-gray-700 border-r border-gray-200">
                                                Hedging End
                                            </div>
                                            <div className="p-2 font-bold text-xs text-gray-700 border-r border-gray-200">
                                                Bought Volume
                                            </div>
                                            <div className="p-2 font-bold text-xs text-gray-700 border-r border-gray-200">
                                                Remaining Volume
                                            </div>
                                            <div className="p-2 font-bold text-xs text-gray-700 border-r border-gray-200">
                                                Ceiling Margin
                                            </div>
                                            <div className="p-2 font-bold text-xs text-gray-700 border-r border-gray-200">
                                                Floor Margin
                                            </div>
                                            <div className="p-2 font-bold text-xs text-gray-700">
                                                Action
                                            </div>
                                        </div>

                                        {/* Data rows */}
                                        {row.original.hedging_data.map(
                                            (detail, index) => (
                                                <div
                                                    key={index}
                                                    className="grid grid-cols-9 border-b border-gray-200"
                                                    style={{
                                                        backgroundColor:
                                                            index % 2 === 0
                                                                ? '#ffffff'
                                                                : '#f9f9f9'
                                                    }}
                                                >
                                                    <div className="p-2 text-sm border-r border-gray-200">
                                                        <span className="text-blue-500 font-medium">
                                                            {detail.volume}
                                                        </span>
                                                    </div>

                                                    <div className="p-2 text-sm border-r border-gray-200">
                                                        {detail.strategy &&
                                                        typeof detail.strategy ===
                                                            'object'
                                                            ? detail.strategy
                                                                  .name || ''
                                                            : detail.strategy ||
                                                              ''}
                                                    </div>

                                                    <div className="p-2 text-sm border-r border-gray-200">
                                                        {
                                                            detail.hedging_start_str
                                                        }
                                                    </div>

                                                    <div className="p-2 text-sm border-r border-gray-200">
                                                        {detail.hedging_end_str}
                                                    </div>

                                                    <div className="p-2 text-sm border-r border-gray-200">
                                                        {detail.volume_bought}
                                                    </div>

                                                    <div className="p-2 text-sm border-r border-gray-200">
                                                        <span
                                                            className={
                                                                detail.volume -
                                                                    detail.volume_bought >
                                                                0
                                                                    ? 'text-blue-500'
                                                                    : 'text-gray-500'
                                                            }
                                                        >
                                                            {detail.volume -
                                                                detail.volume_bought}
                                                        </span>
                                                    </div>

                                                    <div className="p-2 text-sm border-r border-gray-200">
                                                        {detail.strategy
                                                            ?.name ===
                                                        'trailingstop'
                                                            ? detail.strategy
                                                                  ?.trailing_stop_strategy
                                                                  ?.ceiling_margin ||
                                                              ''
                                                            : ''}
                                                    </div>

                                                    <div className="p-2 text-sm border-r border-gray-200">
                                                        {detail.strategy
                                                            ?.name ===
                                                        'trailingstop'
                                                            ? detail.strategy
                                                                  ?.trailing_stop_strategy
                                                                  ?.floor_margin ||
                                                              ''
                                                            : ''}
                                                    </div>

                                                    <div className="p-2 text-sm flex justify-center items-center">
                                                        {Object.keys(
                                                            contract || {}
                                                        ).length > 0 && (
                                                            <Button
                                                                color="primary"
                                                                size="small"
                                                                sx={{
                                                                    fontSize:
                                                                        '12px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    border: '1px solid',
                                                                    padding:
                                                                        '2px 6px',
                                                                    minWidth:
                                                                        'unset'
                                                                }}
                                                                onClick={() => {
                                                                    handleOpenBuyModal(
                                                                        row
                                                                            .original
                                                                            .uuid,
                                                                        detail.uuid,
                                                                        row
                                                                            .original
                                                                            .contract,
                                                                        detail.volume ||
                                                                            0
                                                                    );
                                                                }}
                                                                disabled={
                                                                    detail.volume_bought >=
                                                                        detail.volume &&
                                                                    detail.volume -
                                                                        detail.volume_bought <=
                                                                        0
                                                                }
                                                            >
                                                                Buy
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </Stack>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        mt: 1
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleEditRow(row)}
                                        sx={{
                                            fontSize: '13px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() =>
                                            handleEnsureEditingAndAddDetail(row)
                                        }
                                        sx={{
                                            fontSize: '13px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Add Detail
                                    </Button>
                                </Box>
                            </>
                        ) : (
                            // Editable view (either creating new or editing existing details)
                            <Stack spacing={2}>
                                {(() => {
                                    // Calculate this once outside the mapping function
                                    const originalRow = data.find(
                                        (item) =>
                                            item.uuid === row.original.uuid
                                    );

                                    // Now map through details with the pre-computed original row
                                    return expandedRows[row.original.uuid]?.map(
                                        (detail, index) => {
                                            const originalDetail =
                                                originalRow?.hedging_data?.[
                                                    index
                                                ];
                                            const hasPurchases =
                                                originalDetail?.volume_bought >
                                                0;
                                            const canRemove = !hasPurchases;

                                            return (
                                                <Box
                                                    key={index}
                                                    className="flex items-center gap-2 pb-1 border-b"
                                                >
                                                    <Tooltip
                                                        title={
                                                            hasPurchases
                                                                ? 'Volume cannot be changed after purchases'
                                                                : ''
                                                        }
                                                        arrow
                                                    >
                                                        <span>
                                                            <TextField
                                                                label="Volume"
                                                                type="number"
                                                                value={
                                                                    detail.volume ||
                                                                    ''
                                                                }
                                                                onChange={(e) =>
                                                                    handleDetailChange(
                                                                        row
                                                                            .original
                                                                            .uuid,
                                                                        index,
                                                                        'volume',
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                disabled={
                                                                    hasPurchases
                                                                }
                                                                size="small"
                                                                InputLabelProps={{
                                                                    sx: {
                                                                        fontSize:
                                                                            '14px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        color: 'gray'
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    sx: {
                                                                        fontSize:
                                                                            '14px',
                                                                        fontWeight:
                                                                            'bold',
                                                                        color: hasPurchases
                                                                            ? 'rgba(0, 0, 0, 0.38)'
                                                                            : 'gray'
                                                                    }
                                                                }}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                    <TextField
                                                        label="Hedging Start"
                                                        type="date"
                                                        value={
                                                            detail.hedgingStart ||
                                                            ''
                                                        }
                                                        onChange={(e) =>
                                                            handleDetailChange(
                                                                row.original
                                                                    .uuid,
                                                                index,
                                                                'hedgingStart',
                                                                e.target.value
                                                            )
                                                        }
                                                        size="small"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            sx: {
                                                                fontSize:
                                                                    '14px',
                                                                fontWeight:
                                                                    'bold',
                                                                color: 'gray'
                                                            }
                                                        }}
                                                    />
                                                    <TextField
                                                        label="Hedging End"
                                                        type="date"
                                                        value={
                                                            detail.hedgingEnd ||
                                                            ''
                                                        }
                                                        onChange={(e) =>
                                                            handleDetailChange(
                                                                row.original
                                                                    .uuid,
                                                                index,
                                                                'hedgingEnd',
                                                                e.target.value
                                                            )
                                                        }
                                                        size="small"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            sx: {
                                                                fontSize:
                                                                    '14px',
                                                                fontWeight:
                                                                    'bold',
                                                                color: 'gray'
                                                            }
                                                        }}
                                                    />
                                                    <FormControl
                                                        size="small"
                                                        className="min-w-[200px] w-[10%]"
                                                    >
                                                        <InputLabel
                                                            sx={{
                                                                fontSize:
                                                                    '14px',
                                                                fontWeight:
                                                                    'bold',
                                                                color: 'gray'
                                                            }}
                                                        >
                                                            Strategy
                                                        </InputLabel>

                                                        <Select
                                                            value={
                                                                (detail.strategy &&
                                                                    detail
                                                                        .strategy
                                                                        .name) ||
                                                                ''
                                                            }
                                                            label="Strategy"
                                                            onChange={(e) =>
                                                                handleStrategyChange(
                                                                    row.original
                                                                        .uuid,
                                                                    index,
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            sx={{
                                                                fontSize:
                                                                    '14px',
                                                                fontWeight:
                                                                    'bold',
                                                                color: 'gray'
                                                            }}
                                                        >
                                                            {[
                                                                'trailingstop'
                                                                // 'minbuy',
                                                                // 'maxbuy'
                                                            ].map(
                                                                (strategy) => (
                                                                    <MenuItem
                                                                        key={
                                                                            strategy
                                                                        }
                                                                        value={
                                                                            strategy
                                                                        }
                                                                    >
                                                                        {
                                                                            strategy
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                    {/* Conditionally render extra margin fields for trailingstop */}
                                                    {detail.strategy &&
                                                        detail.strategy.name ===
                                                            'trailingstop' && (
                                                            <>
                                                                <TextField
                                                                    label="Ceiling Margin"
                                                                    type="number"
                                                                    value={
                                                                        detail
                                                                            .strategy
                                                                            .trailing_stop_strategy
                                                                            .ceiling_margin ||
                                                                        ''
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleTrailingMarginChange(
                                                                            row
                                                                                .original
                                                                                .uuid,
                                                                            index,
                                                                            'ceiling_margin',
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    size="small"
                                                                    InputLabelProps={{
                                                                        sx: {
                                                                            fontSize:
                                                                                '14px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            color: 'gray'
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        sx: {
                                                                            fontSize:
                                                                                '14px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            color: 'gray'
                                                                        }
                                                                    }}
                                                                />
                                                                <TextField
                                                                    label="Floor Margin"
                                                                    type="number"
                                                                    value={
                                                                        detail
                                                                            .strategy
                                                                            .trailing_stop_strategy
                                                                            .floor_margin ||
                                                                        ''
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleTrailingMarginChange(
                                                                            row
                                                                                .original
                                                                                .uuid,
                                                                            index,
                                                                            'floor_margin',
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    size="small"
                                                                    InputLabelProps={{
                                                                        sx: {
                                                                            fontSize:
                                                                                '14px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            color: 'gray'
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        sx: {
                                                                            fontSize:
                                                                                '14px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            color: 'gray'
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    {canRemove ? (
                                                        <IconButton
                                                            onClick={() =>
                                                                handleRemoveDetail(
                                                                    row.original
                                                                        .uuid,
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <Remove />
                                                        </IconButton>
                                                    ) : (
                                                        <Tooltip title="Cannot remove after purchases have been made">
                                                            <span>
                                                                <IconButton
                                                                    disabled
                                                                >
                                                                    <Remove />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </Box>
                                            );
                                        }
                                    );
                                })()}
                                {(!expandedRows[row.original.uuid] ||
                                    expandedRows[row.original.uuid].length ===
                                        0) && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() =>
                                                handleEnsureEditingAndAddDetail(
                                                    row
                                                )
                                            }
                                            sx={{
                                                fontSize: '13px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Add Detail
                                        </Button>
                                    </Box>
                                )}
                                {expandedRows[row.original.uuid]?.length >
                                    0 && (
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            variant="contained"
                                            startIcon={<Save />}
                                            onClick={() =>
                                                handleSaveRow(row.original.uuid)
                                            }
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() =>
                                                handleCancelRowEdit(
                                                    row.original.uuid
                                                )
                                            }
                                        >
                                            Cancel Edit
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() =>
                                                handleEnsureEditingAndAddDetail(
                                                    row
                                                )
                                            }
                                            sx={{
                                                fontSize: '13px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            ADD MORE DETAILS
                                        </Button>
                                    </Stack>
                                )}
                            </Stack>
                        )}
                    </Box>
                )}
                muiTableHeadCellProps={{
                    align: 'center',
                    sx: {
                        backgroundColor: '#CBF0E6',
                        color: '#245372',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        borderTop: '2px solid #3DC3C1',
                        borderBottom: '2px solid #3DC3C1'
                    }
                }}
                muiTableBodyRowProps={({ row }) => {
                    // console.log('Row index:', row.index); // Debug to verify index values
                    return {
                        sx: {
                            backgroundColor:
                                row.index % 2 === 0 ? '#ffffff' : '#f5f5f5'
                            // '&:hover': {
                            //     backgroundColor:
                            //         row.index % 2 === 0 ? '#f0f8ff' : '#e6f2ff'
                            // }
                        }
                    };
                }}
                muiTableBodyCellProps={({ cell }) => ({
                    sx: {
                        // fontSize: "10px",
                        // Add border to ALL cells instead of just first and last
                        borderRight: '1px solid #E0E0E0'
                    },
                    align: 'center'
                })}
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    severity="success"
                    onClose={() => setSnackbarOpen(false)}
                >
                    Changes saved successfully!
                </Alert>
            </Snackbar>

            <Snackbar
                open={!!errorMessage}
                autoHideDuration={3000}
                onClose={() => setErrorMessage('')}
            >
                <Alert severity="error" onClose={() => setErrorMessage('')}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default HedgingTable;

import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import Graph from './Graph';
import OverviewTable from './OverviewTable';
import { useSelector } from 'react-redux';
import { getDemand } from '../../../../store/feature/tradingadvisor/tradingAdvisorSlice';

const Overview = ({ contract }) => {
    const [showTable, setShowTable] = useState(true);
    const { demands, isFetching } = useSelector((state) => state.demand);

    const handleShowTable = () => {
        setShowTable(true);
    };

    const handleShowChart = () => {
        setShowTable(false);
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 2,
                    gap: 2
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2
                    }}
                >
                    <TableChartOutlinedIcon
                        sx={{
                            cursor: 'pointer',
                            backgroundColor: showTable
                                ? '#0b9cfd'
                                : 'transparent',
                            borderRadius: '4px'
                        }}
                        onClick={handleShowTable}
                    />
                    <AssessmentOutlinedIcon
                        sx={{
                            cursor: 'pointer',
                            backgroundColor: !showTable
                                ? '#0b9cfd'
                                : 'transparent',
                            borderRadius: '4px'
                        }}
                        onClick={handleShowChart}
                    />
                </Box>
            </Box>
            {showTable ? <OverviewTable contract={contract} /> : <Graph />}
        </Box>
    );
};

export default Overview;

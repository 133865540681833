import React from 'react';
import { Box, Skeleton } from '@mui/material';

const TableSkeleton = ({ 
  rows = 5, 
  columns = 5, 
  rowHeight = 53, 
  headerHeight = 56,
  headerBgColor = '#f5f5f5', 
  evenRowBgColor = '#ffffff',
  oddRowBgColor = '#fafafa'
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      {/* Table header skeleton */}
      <Box sx={{ height: headerHeight, mb: 1, display: 'flex', bgcolor: headerBgColor, borderRadius: 1 }}>
        {Array(columns).fill().map((_, index) => (
          <Box key={index} sx={{ flex: 1, p: 1 }}>
            <Skeleton variant="text" width="80%" height={30} />
          </Box>
        ))}
      </Box>
      
      {/* Table body skeletons */}
      {Array(rows).fill().map((_, rowIndex) => (
        <Box 
          key={rowIndex} 
          sx={{ 
            height: rowHeight, 
            mb: 1, 
            display: 'flex', 
            alignItems: 'center',
            bgcolor: (rowIndex % 2 === 0) ? evenRowBgColor : oddRowBgColor,
            borderRadius: 1
          }}
        >
          {Array(columns).fill().map((_, colIndex) => (
            <Box key={colIndex} sx={{ flex: 1, p: 1 }}>
              <Skeleton 
                variant="text" 
                width={colIndex === columns - 1 ? "40%" : "70%"} 
                height={24} 
              />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default TableSkeleton;
import React, { forwardRef, useEffect, useState } from 'react';
import { CustomModal } from '@components';
import {
    Box,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { apiBuyContract, apiGetOrder } from '@store';
import { showToast } from '@utils';
import { useParams } from 'react-router-dom'; // Import useParams to get UUID from URL
import {
    triggerPurchasesRefetch,
    getDemand,
    getPurchases
} from '@store/feature/tradingadvisor/tradingAdvisorSlice';

const DeliveryPoint = ['THE', 'TTF', 'PEG'];

const Buy = forwardRef(
    (
        {
            handleClose,
            selectedContract,
            user,
            productTypeMarket,
            buyVolume,
            shoppingItemUUID,
            hedgingItemUUID
        },
        ref
    ) => {
        const dispatch = useDispatch();

        const { uuid } = useParams(); // Get UUID from URL
        console.log('Hedgin item uuid is: ', hedgingItemUUID);

        const typeofProductMarket =
            productTypeMarket === 'THE'
                ? 'THE'
                : productTypeMarket === 'TTF'
                ? 'TTF'
                : 'PEG';

        //initializing state based on product type
        const [state, setState] = useState({
            market: typeofProductMarket,
            type: 'buy',
            price_selected: '',
            seller: '',
            buyer: user?.CompanyShort,
            deliverypoint: typeofProductMarket,
            volume: buyVolume,
            exitpoint: '',
            price_percent: '100',
            ordertype: 'internal',
            currency: 'EUR',
            sym: selectedContract?.key
        });

        const handleChange = (e) => {
            const { name, value } = e.target;
            setState({
                ...state,
                [name]: value
            });
        };

        const [type, setType] = useState('ask');
        const [submitting, setSubmitting] = useState(false);

        const handleSubmit = async (e) => {
            e.preventDefault();
            if (submitting) return;
            setSubmitting(true);
            const volume = parseFloat(state.volume);
            const ask = parseFloat(selectedContract.ask);

            const data = {
                ...state,
                volume,
                price: { ask },
                shoppinglist_uuid: shoppingItemUUID,
                hedgingdata_uuid: hedgingItemUUID,
                demand_uuid: uuid,
                exitpoint: state.exitpoint || {} // if endpoint is not selected, default to an empty object
            };

            const resp = await dispatch(apiBuyContract(data));
            if (resp?.meta?.requestStatus === 'fulfilled') {
                handleClose();
                dispatch(apiGetOrder());
                showToast('success', 'Purchase order succesfully saved');
                dispatch(getDemand(uuid));
                dispatch(getPurchases(uuid));
                dispatch(triggerPurchasesRefetch());

            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp?.payload || 'Error buying contract');
                setSubmitting(false);
            }
        };

        // timer
        const [timer, setTimer] = useState(20);

        useEffect(() => {
            const interval = setInterval(() => {
                setTimer((timer) => timer - 1);
            }, 1000);
            return () => clearInterval(interval);
        }, [timer]);

        useEffect(() => {
            if (timer === 0) {
                handleClose();
            }
        }, [timer, handleClose]);

        useEffect(() => {
            if (ref.current) {
                setTimer(20);
            }
        }, [ref]);

        return (
            <CustomModal
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                modalTitle={`Buy Contract`}
                timer={timer}
                buttonText={'Buy'}
                disabled={!state.volume || submitting}
                ref={ref}
            >
                <Box className="flex flex-col gap-3">
                    <FormControl fullWidth>
                        <InputLabel
                            id="demo-simple-select-label"
                            sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                        >
                            Please Select what you would like to buy
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Type"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <MenuItem value="ask">Ask Price</MenuItem>
                        </Select>
                    </FormControl>
                    <Box className="flex gap-3">
                        <Typography>Contract:</Typography>
                        <Typography color={'black'}>
                            {selectedContract.contract}
                        </Typography>
                    </Box>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            id="contractLabel"
                            sx={{ bgcolor: '#F5F5F5', p: '0 3px' }}
                        >
                            Volume
                        </InputLabel>
                        <OutlinedInput
                            id="volume"
                            label="Volume"
                            name="volume"
                            type="number"
                            onChange={handleChange}
                            value={state.volume}
                            inputProps={{ step: 0.01 }}
                            required
                            endAdornment={
                                <InputAdornment position="start">
                                    MW
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel
                            id="demo-simple-select-label"
                            sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                        >
                            Endpoint
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Endpoint"
                            name="exitpoint"
                            value={state.exitpoint}
                            onChange={handleChange}
                        >
                            {user?.Endpoints?.map((endpoint, index) => (
                                <MenuItem key={index} value={endpoint}>
                                    {' '}
                                    {endpoint.name} - {endpoint.location}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel
                            id="demo-simple-select-label"
                            sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                        >
                            Seller
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Seller"
                            name="seller"
                            value={state.seller}
                            onChange={handleChange}
                        >
                            {user?.CanBuyFrom?.map((seller, index) => (
                                <MenuItem key={index} value={seller}>
                                    {seller}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel
                            id="demo-simple-select-label"
                            sx={{ bgcolor: '#F5F5F5', pr: 1 }}
                        >
                            Delivery Point
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Delivery Point"
                            name="deliverypoint"
                            value={state.deliverypoint}
                            onChange={handleChange}
                        >
                            {DeliveryPoint?.map((delivery_point, index) => (
                                <MenuItem key={index} value={delivery_point}>
                                    {delivery_point}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box className="flex gap-3">
                        <Typography>Price:</Typography>
                        <Typography color={'black'}>
                            {selectedContract.ask}
                        </Typography>
                    </Box>
                    {/* <Box className='flex gap-3'>
                    <Typography>Settlement Amount:</Typography>
                    <Typography color={'black'}>{selectedContract.ask * state?.volume || 0} </Typography >
                </Box> */}
                    <Box className="flex gap-3">
                        <Typography>Last Updated:</Typography>
                        <Typography color={'black'}>
                            {selectedContract?.updated_at_cet_str}
                        </Typography>
                    </Box>
                </Box>
            </CustomModal>
        );
    }
);

export default Buy;

import React, { useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, Typography, Chip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDemand } from '../../../../store/feature/tradingadvisor/tradingAdvisorSlice';
import ReactTable from '@components/ReactTable'; // Added import

const OverviewTable = ({ contract, user }) => {
    const { selectedDemand } = useSelector((state) => state.demand);
    const shoppingList = selectedDemand?.shopping_list;

    // Flatten the shopping list data
    const flattenedData = useMemo(() => {
        return (shoppingList || []).flatMap((item) =>
            item.hedging_data.map((detail) => {
                // Convert strategy and margins to strings if they are objects
                const strategyDisplay = detail.strategy
                    ? typeof detail.strategy === 'object'
                        ? detail.strategy.name
                        : detail.strategy
                    : '';
                const ceilingMarginDisplay =
                    detail.strategy && typeof detail.strategy === 'object'
                        ? detail.strategy.trailing_stop_strategy
                              ?.ceiling_margin || ''
                        : '';
                const floorMarginDisplay =
                    detail.strategy && typeof detail.strategy === 'object'
                        ? detail.strategy.trailing_stop_strategy
                              ?.floor_margin || ''
                        : '';

                return {
                    // Parent info
                    uuid: item.uuid,
                    contract: item.contract,
                    product: item.product,
                    totalVolume: item.volume,
                    signal: item.signal,
                    price:
                        contract && contract[item.contract]
                            ? contract[item.contract].ask
                            : 'N/A',
                    // Hedging detail info
                    hedgingUuid: detail.uuid,
                    volume: detail.volume,
                    strategy: strategyDisplay,
                    hedgingStart: detail.hedging_start_str,
                    hedgingEnd: detail.hedging_end_str,
                    volumeBought: detail.volume_bought || 0,
                    remainingVolume:
                        detail.volume - (detail.volume_bought || 0),
                    ceilingMargin: ceilingMarginDisplay,
                    floorMargin: floorMarginDisplay,
                    isCompleted: (detail.volume_bought || 0) >= detail.volume,
                    demandUuid: item.demand_uuid
                };
            })
        );
    }, [shoppingList, contract]);

    // Define columns without any Action column
    const columns = useMemo(
        () => [
            {
                accessorKey: 'contract',
                header: 'Contract',
                size: 100,
                Cell: ({ renderedCellValue }) => (
                    <Typography
                        sx={{
                            color: '#27A9FF',
                            fontWeight: 'bold',
                            fontSize: '14px'
                        }}
                    >
                        {renderedCellValue}
                    </Typography>
                )
            },
            // {
            //     accessorKey: 'product',
            //     header: 'Product',
            //     size: 100
            // },
            // {
            //     accessorKey: 'price',
            //     header: 'Price in EUR',
            //     Cell: ({ row }) => (
            //         <Typography
            //             sx={{
            //                 color: '#27A9FF',
            //                 fontWeight: 'bold',
            //                 fontSize: '14px'
            //             }}
            //         >
            //             {contract && contract[row.original.contract]
            //                 ? contract[row.original.contract].ask
            //                 : 'No price available'}
            //         </Typography>
            //     ),
            //     size: 140
            // },
            // {
            //     accessorKey: 'signal',
            //     header: 'Signal',
            //     size: 80
            // },
            {
                accessorKey: 'volume',
                header: 'Volume',
                size: 80,
                Cell: ({ renderedCellValue }) => (
                    <Typography
                        sx={{
                            color: 'teal',
                            fontWeight: 'bold',
                            fontSize: '14px'
                        }}
                    >
                        {renderedCellValue}
                    </Typography>
                )
            },
            {
                accessorKey: 'strategy',
                header: 'Strategy',
                size: 120
            },
            {
                accessorKey: 'hedgingStart',
                header: 'Hedging Start',
                size: 120
            },
            {
                accessorKey: 'hedgingEnd',
                header: 'Hedging End',
                size: 120
            },
            {
                accessorKey: 'volumeBought',
                header: 'Bought Volume',
                size: 110
            },
            {
                accessorKey: 'remainingVolume',
                header: 'Remaining',
                size: 100,
                Cell: ({ renderedCellValue }) => (
                    <Typography
                        sx={{
                            color: renderedCellValue > 0 ? 'blue' : 'gray',
                            fontWeight:
                                renderedCellValue > 0 ? 'bold' : 'normal'
                        }}
                    >
                        {renderedCellValue}
                    </Typography>
                )
            },
            {
                accessorKey: 'ceilingMargin',
                header: 'Ceiling Margin',
                size: 120,
                Cell: ({ row }) =>
                    row.original.strategy === 'trailingstop'
                        ? row.original.ceilingMargin
                        : ''
            },
            {
                accessorKey: 'floorMargin',
                header: 'Floor Margin',
                size: 120,
                Cell: ({ row }) =>
                    row.original.strategy === 'trailingstop'
                        ? row.original.floorMargin
                        : ''
            },
            {
                id: 'status',
                header: 'Status',
                size: 120,
                Cell: ({ row }) => {
                    const isCompleted = row.original.isCompleted;
                    return isCompleted ? (
                        <Chip
                            label="Completed"
                            color="success"
                            size="small"
                            sx={{ fontWeight: 'bold' }}
                        />
                    ) : (
                        <Chip
                            label="Progressing"
                            color="warning"
                            size="small"
                            sx={{ fontWeight: 'bold' }}
                        />
                    );
                }
            }
        ],
        [contract]
    );

    return (
        <Box className="w-full">
            <ReactTable
                columns={columns}
                data={flattenedData}
                headerText="Overview"
                showCellBorders
                // ...pass any additional props as needed from existing configuration...
            />
        </Box>
    );
};

export default OverviewTable;

import React from 'react';
import { Alert, AlertTitle, Box, Button, Paper, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const Error = ({ 
  message = 'An unexpected error occurred', 
  severity = 'error', 
  retry = null,
  goBack = false,
  title = 'Error',
  fullPage = false
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const content = (
    <Box sx={{ textAlign: 'center', py: 2 }}>
      <ErrorOutlineIcon color={severity} sx={{ fontSize: 60, mb: 2 }} />
      
      <Alert severity={severity} sx={{ mb: 2, mx: 'auto', maxWidth: '500px' }}>
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
      
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
        {retry && (
          <Button 
            variant="contained" 
            color="primary" 
            startIcon={<RefreshIcon />}
            onClick={retry}
          >
            Try Again
          </Button>
        )}
        
        {goBack && (
          <Button 
            variant="outlined" 
            startIcon={<ArrowBackIcon />}
            onClick={handleGoBack}
          >
            Go Back
          </Button>
        )}
      </Box>
    </Box>
  );

  if (fullPage) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: 'calc(100vh - 64px)' // Adjust for your app's header height
      }}>
        <Paper elevation={3} sx={{ p: 4, maxWidth: '600px', width: '100%' }}>
          {content}
        </Paper>
      </Box>
    );
  }

  return content;
};

export default Error;
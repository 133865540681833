import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
    Box,
    Typography,
    Popover,
    Chip,
    Snackbar,
    Alert,
    CircularProgress
} from '@mui/material';
import { DateRangePicker } from 'react-date-range';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useParams } from 'react-router-dom'; // Import useParams to get UUID from URL
import ShoppingListImg from '../../../../assets/svg/shopping-list.svg';
import { MaterialReactTable } from 'material-react-table'; // Use Material React Table directly
import {
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table';
import { getPurchases } from '@store/feature/tradingadvisor/tradingAdvisorSlice';
import { useDispatch, useSelector } from 'react-redux';
import TableSkeleton from '../Skeleton';
import { exportPurchasesToCSV } from '@utils/purchasesCsvExport';
import DownloadIcon from '@mui/icons-material/Download';

const YourPurchases = () => {
    const { uuid } = useParams(); // Get UUID from URL

    const dispatch = useDispatch();
    const { purchases, isFetching, lastPurchaseTime } = useSelector(
        (state) => state.demand
    );
    console.log('purchases:', purchases);
    //converting lastPurchaseTime to utc +5.45 time
    // Create ref to store previous value
    const prevLastPurchaseTimeRef = useRef(lastPurchaseTime);
    console.log(
        '🚀 ~ YourPurchases ~ prevLastPurchaseTimeRef:',
        prevLastPurchaseTimeRef
    );

    useEffect(() => {
        console.log('Effect running with lastPurchaseTime:', lastPurchaseTime);
        console.log('Current ref value:', prevLastPurchaseTimeRef.current);

        // More robust check that handles both array and nested data structure
        const hasData = Array.isArray(purchases)
            ? purchases.length > 0
            : purchases?.data && Array.isArray(purchases.data)
            ? purchases.data.length > 0
            : false;

        // Only fetch if no data or if lastPurchaseTime changed
        if (!hasData || lastPurchaseTime !== prevLastPurchaseTimeRef.current) {
            console.log('Time are not equal');
            const fetchPurchases = async () => {
                try {
                    console.log('Fetching purchases for uuid:', uuid);
                    await dispatch(getPurchases(uuid));
                    prevLastPurchaseTimeRef.current = lastPurchaseTime;
                } catch (error) {
                    console.error('Error fetching purchases:', error);
                    setFetchError(error.message);
                }
            };

            fetchPurchases();
        } else {
            console.log('Skipping fetch - data exists and timestamp unchanged');
        }
    }, [uuid, lastPurchaseTime, dispatch]);
    // const abcd = new Date(lastPurchaseTime).toLocaleString('en-US', {
    //     timeZone: 'Asia/Kathmandu'
    // });

    const [state, setState] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'date-range-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [fetchError, setFetchError] = useState(''); // keep error state for UI feedback

    // Use useMemo to memoize sorted purchases
    const sortedPurchases = useMemo(() => {
        // Check if purchases is an array or if it has a data property
        const purchasesArray = Array.isArray(purchases)
            ? purchases
            : purchases?.data || [];

        if (!purchasesArray.length) return [];

        return [...purchasesArray].sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
    }, [purchases]);

    // Memoize filtered purchases
    const filteredPurchases = useMemo(() => {
        if (!sortedPurchases.length) return [];

        return state[0].startDate && state[0].endDate
            ? sortedPurchases.filter(
                  (purchase) =>
                      new Date(purchase.created_at) >= state[0].startDate &&
                      new Date(purchase.created_at) <= state[0].endDate
              )
            : sortedPurchases;
    }, [state, sortedPurchases]);

    // Calculate total volume and costs based on visible rows
    const calculateTotals = (rows) => {
        const totalVolume = rows.reduce(
            (acc, purchase) => acc + parseFloat(purchase.data.volume),
            0
        );
        const totalCosts = rows.reduce(
            (acc, purchase) =>
                acc +
                parseFloat(purchase.data.price.ask) *
                    parseFloat(purchase.data.volume),
            0
        );
        return { totalVolume, totalCosts };
    };

    const { totalVolume, totalCosts } = calculateTotals(filteredPurchases);

    const handleDownloadCSV = () => {
        console.log('Filtered Purchases', filteredPurchases);
        exportPurchasesToCSV(columns, filteredPurchases, 'purchases.csv');
    };

    // Updated columns using material-react-table format
    const columns = [
        {
            accessorKey: 'data.sym',
            header: 'Contract',
            size: 120,
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        color: 'teal',
                        fontWeight: 'bold',
                        fontSize: '15px'
                    }}
                >
                    {row.original.data.sym}
                </Typography>
            )
        },
        {
            accessorKey: 'data.buyer',
            header: 'Buyer',
            size: 60,
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        fontSize: '15px'
                    }}
                >
                    {row.original.data.buyer}
                </Typography>
            )
        },
        {
            accessorKey: 'data.seller',
            header: 'Seller',
            size: 60,
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        color: 'black',
                        fontSize: '15px'
                    }}
                >
                    {row.original.data.seller}
                </Typography>
            )
        },
        {
            accessorKey: 'data.deliverypoint',
            header: 'Delivery-Point',
            id: 'deliverypoint',
            size: 60,
            Cell: ({ row }) => {
                const { deliverypoint } = row.original.data;
                return (
                    <Typography
                        sx={{
                            fontSize: '15px'
                        }}
                    >
                        {deliverypoint}
                    </Typography>
                );
            }
        },
        {
            accessorKey: 'data.volume',
            header: 'Volume',
            id: 'volume',
            size: 60,
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        fontSize: '15px'
                    }}
                >
                    {row.original.data.volume}
                </Typography>
            )
        },
        {
            accessorKey: 'data.units',
            header: 'Units',
            id: 'units',
            size: 65,
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        color: '#27A9FF',
                        fontSize: '15px'
                    }}
                >
                    MWh/h
                </Typography>
            )
        },
        {
            accessorKey: 'data.price.ask',
            header: 'Price',
            id: 'price',
            size: 80,
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        color: 'black',
                        fontSize: '15px'
                    }}
                >
                    {row.original.data.price.ask}
                </Typography>
            )
        },
        {
            accessorKey: 'data.currency',
            header: 'Currency',
            id: 'currency',
            size: 55,
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        fontSize: '15px'
                    }}
                >
                    {row.original.data.currency}
                </Typography>
            )
        },
        {
            accessorKey: 'data.type',
            header: 'Type',
            id: 'type',
            size: 50,
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        color: '#27A9FF',
                        fontSize: '15px'
                    }}
                >
                    {row.original.data.type}
                </Typography>
            )
        },

        {
            accessorKey: 'data.status',
            header: 'Status',
            accessorFn: ({ data }) => data.status || 'Completed',
            size: 80
        },

        {
            accessorKey: 'username',
            header: 'Account',
            id: 'username',
            size: 150,
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        color: 'black',
                        fontSize: '15px'
                    }}
                >
                    {row.original.username}
                </Typography>
            )
        },
        {
            accessorKey: 'created_at',
            header: 'Created-At',
            size: 120, // Explicit size constraint
            Cell: ({ row }) => (
                <Typography
                    sx={{
                        color: 'black',
                        fontSize: '15px'
                    }}
                >
                    {row.original.created_at}
                </Typography>
            )
        }
    ];

    return (
        <Box>
            {isFetching ? (
                <TableSkeleton rows={5} columns={5} />
            ) : purchases.length === 0 ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 3,
                        width: '100%',
                        padding: '20px',
                        justifyContent: 'center',
                        height: 'calc(100vh - 250px)'
                    }}
                >
                    <img
                        src={ShoppingListImg}
                        alt="Shopping List"
                        style={{
                            width: '200px',
                            height: '200px',
                            objectFit: 'contain'
                        }}
                    />
                    <Typography>
                        This section is currently empty. Go to "Demand" to
                        generate Purchase List.
                    </Typography>
                </Box>
            ) : (
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginBottom: 2
                        }}
                    >
                        <Box
                            onClick={handleClick}
                            sx={{
                                display: 'flex',
                                border: '1px solid #6b7280',
                                color: '#6b7280',
                                gap: 1,
                                padding: 1,
                                cursor: 'pointer',
                                borderRadius: '5px',
                                alignItems: 'center',
                                width: '350px'
                            }}
                        >
                            <CalendarMonthIcon fontSize="small" />
                            <Typography>
                                {state[0].startDate
                                    ? state[0].startDate.toDateString()
                                    : 'Select start date'}{' '}
                                -{' '}
                                {state[0].endDate
                                    ? state[0].endDate.toDateString()
                                    : 'Select end date'}
                            </Typography>
                        </Box>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                        >
                            <DateRangePicker
                                onChange={(item) => setState([item.selection])}
                                showSelectionPreview
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={state}
                                direction="horizontal"
                                preventSnapRefocus
                                calendarFocus="backwards"
                                rangeColors={['#2facfe']}
                            />
                        </Popover>
                    </Box>
                    <Box style={{ width: '100%' }}>
                        <MaterialReactTable
                            data={filteredPurchases}
                            columns={columns}
                            headerText="Your Purchases"
                            enableSorting={false}
                            enableColumnActions={false}
                            state={{
                                isLoading: isFetching
                            }}
                            initialState={{
                                pagination: {
                                    pageSize: 20, // Set initial page size to 50
                                    pageIndex: 0 // Start at first page
                                },
                                density: 'compact'
                            }}
                            renderTopToolbarCustomActions={() => (
                                <Typography variant="h6" color="#245372" sx={{ fontWeight: 'bold', ml: 2 }}>
                                    Your Purchases
                                </Typography>
                            )}
                            renderToolbarInternalActions={({ table }) => (
                                <Box
                                    sx={{
                                        display: 'flex'
                                    }}
                                    onMouseDown={(e) => e.stopPropagation()}
                                >
                                    <Box
                                        component="button"
                                        onClick={handleDownloadCSV}
                                        sx={{
                                            cursor: 'pointer',
                                            background: 'none',
                                            border: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            p: '0.1rem',
                                            '&:hover': {
                                                backgroundColor: '#f5f5f5'
                                            }
                                        }}
                                        aria-label="Download CSV"
                                    >
                                        <DownloadIcon
                                            sx={{
                                                color: 'gray'
                                            }}
                                        />
                                    </Box>
                                    <MRT_ToggleGlobalFilterButton
                                        table={table}
                                    />
                                    <MRT_ToggleDensePaddingButton
                                        table={table}
                                    />
                                </Box>
                            )}
                            muiTableHeadCellProps={{
                                align: 'center',
                                sx: {
                                    backgroundColor: '#CBF0E6',
                                    color: '#245372',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    borderTop: '2px solid #3DC3C1',
                                    borderBottom: '2px solid #3DC3C1'
                                }
                            }}
                            muiTableContainerProps={{
                                sx: {
                                    maxHeight: 'calc(100vh - 150px)',
                                    overflow: 'auto'
                                }
                            }}
                            muiTableBodyProps={{
                                sx: {
                                    '& > tr:nth-of-type(odd)': {
                                        backgroundColor: '#f5f5f5 !important'
                                    },
                                    '& > tr:nth-of-type(even)': {
                                        backgroundColor: '#ffffff !important'
                                    }
                                }
                            }}
                            muiTableBodyRowProps={({ row }) => ({
                                sx: {
                                    backgroundColor:
                                        row.index % 2 === 0
                                            ? '#ffffff !important'
                                            : '#f5f5f5 !important'
                                },
                                onClick: () => console.log(row.original)
                            })}
                            muiTableBodyCellProps={({ cell }) => ({
                                sx: {
                                    // fontSize: "10px",
                                    // Add border to ALL cells instead of just first and last
                                    borderRight: '1px solid #E0E0E0'
                                },
                                align: 'center'
                            })}
                        />
                        {/* Insert totals container below the table */}
                        <Box
                            display="flex"
                            gap={4}
                            alignItems="center"
                            sx={{ p: 1, marginTop: 2 }}
                        >
                            <Box display="flex" alignItems="center" gap={1}>
                                <Typography fontWeight={600}>
                                    Total Volume
                                </Typography>
                                <Chip
                                    label={`${totalVolume.toFixed(2)} MW/h`}
                                    sx={{
                                        bgcolor: '#ebedf4',
                                        fontWeight: 500
                                    }}
                                />
                            </Box>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Typography fontWeight={600}>
                                    Total Costs
                                </Typography>
                                <Chip
                                    label={`${totalCosts.toFixed(2)} EUR`}
                                    sx={{
                                        bgcolor: '#ebedf4',
                                        fontWeight: 500
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            {/* New Snackbar for fetch errors */}
            <Snackbar
                open={Boolean(fetchError)}
                autoHideDuration={3000}
                onClose={() => setFetchError('')}
            >
                <Alert severity="error" onClose={() => setFetchError('')}>
                    {fetchError}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default YourPurchases;

import React from "react";
import { Typography } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

const ReactTable = (props) => {
    const {
        data,
        isLoading,
        handleRowClick,
        columns,
        actions,
        headerText,
        renderToolbarInternalActions,
        showCursorPointerOnRowHover,
        renderBottomToolbar,
        muiTableBodyRowProps,
        muiTableBodyProps, // incoming props
        showCellBorders = false, // New prop with default value

    } = props;

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowActions: !!actions,
        renderRowActions: actions,
        positionActionsColumn: "last",
        state: {
            isLoading: isLoading,
        },
        initialState: {
            density: "compact",
            expanded: false,
            pagination: { pageIndex: 0, pageSize: 100 },
        },
        renderToolbarInternalActions: renderToolbarInternalActions && renderToolbarInternalActions,
        renderBottomToolbar: renderBottomToolbar && renderBottomToolbar,
        renderTopToolbarCustomActions: () => (
            <Typography component="span" variant="h6" color={'#245372'}>
                {headerText}
            </Typography>
        ),

        enableGrouping: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableFilters: true,
        enableColumnActions: false,
        enableSorting: false,
        muiTableBodyRowProps: muiTableBodyRowProps || (({ row }) => ({
            sx: {
                cursor:
                    (row?.original?.host_url && handleRowClick && "pointer") ||
                    (showCursorPointerOnRowHover && "pointer"),
            },
        })),
        muiBottomToolbarProps: {
            onMouseDown: (event) => {
                event.stopPropagation();
            },
        },
        muiTableProps: {
            onMouseDown: (event) => {
                event.stopPropagation();
            },
        },
        muiToolbarAlertBannerChipProps: {
            onMouseDown: (event) => {
                event.stopPropagation();
            },
        },
        muiTableBodyProps: {
            ...(muiTableBodyProps || {}),
            onMouseDown: (event) => {
                event.stopPropagation();
                muiTableBodyProps?.onMouseDown && muiTableBodyProps.onMouseDown(event);
            },
            sx: {
                ...(muiTableBodyProps?.sx || {}),
                '& tr:nth-of-type(odd) > td': {
                    backgroundColor: '#f5f5f5',
                },
            },
        },
        muiSearchTextFieldProps: {
            variant: "standard",
            size: "small",
            sx: {
                width: "100%",
                maxWidth: "150px",
                top: "50%",
                transform: "translateY(-50%)",
            },
        },
        muiTableContainerProps: {
            sx: {
                maxHeight: "calc(100vh - 150px)",
                overflow: "auto",
            },
        },
        muiTableHeadRowProps: {
            sx: {
                backgroundColor: '#CBF0E6',
                fontWeight: 'bold',
                fontSize: '14px',
            },
        },
        muiTableHeadCellProps: {
            align: 'center',
            sx: {
                color: '#245372',
                borderTop: '2px solid #3DC3C1',
                borderBottom: '2px solid #3DC3C1',
                fontSize: '16px',
            }
        },
        muiTableFooterCellProps: {
            align: 'center',
        },
        muiTableBodyCellProps: ({ cell }) => ({
            sx: {
                fontSize: "16px",
                cursor: handleRowClick && "pointer",
                // Use borders based on the showCellBorders prop
                borderRight: showCellBorders 
                    ? '1px solid #E0E0E0' 
                    : (cell.column.id === columns[0]?.id || cell.column.id === columns[columns.length - 1]?.id) ? '1px solid #E0E0E0' : '',
            },
            align: 'center',
            onClick: () => {
                handleRowClick && handleRowClick(cell?.row?.original);
            },
        }),
        
    });

    return (
        <MaterialReactTable
            table={table}
            {...props}
        />
    );
};

export default ReactTable;

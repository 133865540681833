// src/utils/csvExport.js
export const exportTableToCSV = (columns, data, filename = 'table_data.csv') => {
    // Get headers from columns
    const headers = columns.map(col => col.header || col.id).join(',');
  
    // Process rows with proper data extraction
    const rows = data.map(row => {
      return columns
        .map(col => {
          let value = '';
          
          // First try to use the column's accessorFn if available
          if (col.accessorFn) {
            try {
              value = col.accessorFn(row);
            } catch (e) {
              // If accessorFn fails, continue to other methods
            }
          }
          
          // If value is still empty, try other extraction methods
          if (value === '' && col.id) {
            // Check for nested data based on your table structure
            if (col.id === 'contract') {
              value = row?.data?.sym || row?.contract || '';
            } 
            else if (col.id === 'buyer') {
              value = row?.data?.buyer || '';
            }
            else if (col.id === 'seller') {
              value = row?.data?.seller || '';
            }
            else if (col.id === 'deliverypoint') {
              value = row?.data?.deliverypoint ||row?.data?.delivery_point || '';
            }
            else if (col.id === 'volume') {
              value = row?.data?.vol || row?.volume || '';
            }
            else if (col.id === 'units') {
              value = row?.data?.units || '';
            }
            else if (col.id === 'price') {
              value = row?.data?.price || '';
            }
            else if (col.id === 'currency') {
              value = row?.data?.currency || '';
            }
            else if (col.id === 'type') {
              value = row?.data?.type || '';
            }
            else if (col.id === 'order_status') {
              value = row?.order_status || 'Completed';
            }
            else if (col.id === 'username') {
              value = row?.username || '';
            }
            else if (col.id === 'created_at') {
              value = row?.created_at || '';
            }
            // Direct property access as fallback
            else {
              value = row[col.id] || '';
            }
          }
  
          // Format for CSV
          if (value === null || value === undefined) value = '';
          if (typeof value === 'string') {
            value = value.replace(/"/g, '""');
            if (value.includes(',')) value = `"${value}"`;
          }
          
          return value;
        })
        .join(',');
    }).join('\n');
  
    // Create and download the CSV
    const csvContent = `${headers}\n${rows}`;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
import React, { forwardRef, useState, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { showToast } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllDemands,
    uploadDemand
} from '../../../../store/feature/tradingadvisor/tradingAdvisorSlice';
import { Loader } from 'lucide-react';

const UploadDemandModal = forwardRef(({ handleClose }, ref) => {
    const dispatch = useDispatch();
    const { isUploading: loading, error } = useSelector(
        (state) => state.demand
    );
    const [state, setState] = useState({
        demandName: '',
        csvFile: null,
        csvFileName: '',
        year: '',
        optionType: ''
    });

    const fileInputRef = useRef(null);

    const currentYear = new Date().getFullYear();
    const years = [
        currentYear,
        ...Array.from({ length: 5 }, (_, i) => currentYear + i + 1)
    ];

    const handleFiles = (files) => {
        const file = files[0];
        if (file.type !== 'text/csv') {
            showToast('error', 'Please upload a valid CSV file');
            return;
        }

        setState((prev) => ({
            ...prev,
            csvFile: file,
            csvFileName: file.name
        }));

        // Reset file input to allow re-uploading the same file
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        handleFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv']
        },
        maxSize: 30 * 1024 * 1024, // 30MB
        multiple: false
    });

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!state.csvFile) {
            showToast('error', 'Please upload a CSV file');
            return;
        }
        if (!state.demandName || !state.year || !state.optionType) {
            showToast('error', 'Please fill all fields');
            return;
        }

        const formData = new FormData();
        formData.append('demandName', state.demandName);
        formData.append('year', state.year);
        formData.append('optionType', state.optionType);
        formData.append('file', state.csvFile);

        try {
            await dispatch(uploadDemand(formData)).unwrap();
            showToast('info', 'CSV uploaded successfully');
            handleClose();
            dispatch(getAllDemands());
        } catch (error) {
            showToast('error', error.payload || 'Upload failed');
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg w-full max-w-lg">
                {/* Header */}
                <div className="bg-[#1e4976] text-white p-4 flex justify-between items-center rounded-t-lg">
                    <h2 className="text-lg font-medium">Upload Demand</h2>
                    <button
                        onClick={handleClose}
                        className="text-white hover:text-gray-200 text-2xl"
                    >
                        ×
                    </button>
                </div>

                {/* Content */}
                <form onSubmit={handleSubmit} className="p-6">
                    {loading && (
                        <div className="text-center mb-4">
                            <Loader />
                        </div>
                    )}

                    {/* File Upload Area */}
                    <div
                        {...getRootProps()}
                        className={`border-2 border-dashed rounded-lg p-8 mb-6 transition-colors cursor-pointer
                           hover:border-blue-400 hover:bg-blue-50
                           ${
                               isDragActive
                                   ? 'border-blue-400 bg-blue-50'
                                   : 'border-gray-300'
                           }
                           ${state.csvFile ? 'bg-gray-50' : ''}`}
                    >
                        <input {...getInputProps()} ref={fileInputRef} />
                        <div className="text-center">
                            {state.csvFile ? (
                                <div className="flex flex-col items-center">
                                    <svg
                                        className="w-8 h-8 text-green-500 mb-2"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                    <span className="text-gray-700">
                                        {state.csvFileName}
                                    </span>
                                    <span className="text-sm text-gray-500 mt-2 hover:text-blue-500">
                                        Click or drag to replace
                                    </span>
                                </div>
                            ) : (
                                <div className="flex flex-col items-center">
                                    <svg
                                        className="w-8 h-8 text-gray-400 mb-2 group-hover:text-blue-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                        />
                                    </svg>
                                    <span className="text-gray-600 hover:text-blue-500">
                                        Click to Upload
                                    </span>
                                    <span className="text-gray-500 text-sm">
                                        or drag and drop
                                    </span>
                                    <span className="text-gray-400 text-xs mt-1">
                                        Max. File Size: 30MB
                                    </span>
                                    <span className="text-red-700 text-xs mt-1">
                                        Note: Same demand profile will be used
                                        for consecutive years
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Demand Name */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Demand Name
                        </label>
                        <input
                            type="text"
                            name="demandName"
                            value={state.demandName}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                        />
                    </div>

                    {/* Year Selection */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Year
                            {/* <br />{' '}
                           <span className="text-red-700 text-xs mt-1">
                               Note: Same demand profile will be used for
                               consecutive years
                           </span> */}
                        </label>
                        <select
                            name="year"
                            value={state.year}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                        >
                            <option value="">Select Year</option>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Option Type Selection */}
                    <div className="mb-6">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Option Type
                        </label>
                        <select
                            name="optionType"
                            value={state.optionType}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                        >
                            <option value="">Select Option Type</option>
                            <option value="THE">THE</option>
                            <option value="PEG">PEG</option>
                            <option value="TTF">TTF</option>
                        </select>
                    </div>

                    {/* Buttons */}
                    <div className="flex justify-end space-x-3">
                        <button
                            onClick={handleClose}
                            className="px-4 py-2 border border-gray-300 rounded-md"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-600 text-white rounded-md"
                        >
                            Upload
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
});

export default UploadDemandModal;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { demandLandingPageData } from '@data';

const CurvedDottedLine = () => {
    return (
        <svg width="100%" height="300px" style={{ position: 'absolute', top: '175px', left: 0, zIndex: 1 }}>
            <path
                // d="M 150 50 Q 450 -50 750 50 Q 1050 150 1350 50"
                // make it more curved at the top and 
                d="M 150 50 Q 450 -50 750 50 Q 1050 150 1350 50"
                stroke="black"
                strokeWidth="2" // Increased width of the line
                fill="transparent"
                strokeDasharray="2"
            />
        </svg>
    );
};

const LandingPage = () => {
    return (
        <Box
            sx={{
                mt: 5,
                position: 'relative',
                zIndex: 1,
            }}
        >
            <Box>
                <Typography
                    sx={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        mb: 1
                    }}
                >
                    Welcome to our Trading Advisor!
                </Typography>
                <Typography
                    sx={{
                        fontSize: '14px',
                        mb: 2
                    }}
                >
                    Easily streamline your trading experience with our AI Trading Advisor in 3 simple steps. Here's how it works:
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" position="relative">
                <CurvedDottedLine />
                {demandLandingPageData.map((data, index) => (
                    <Box
                        key={data.id}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 1,
                            width: '30%',
                            padding: '20px',
                            zIndex: 1,
                        }}
                    >
                        <img
                            src={data.image}
                            alt={data.title}
                            style={{
                                width: '350px', // Adjusted width to better fit the SVG curve
                                height: '350px',
                                objectFit: 'contain',
                                marginBottom: '10px'
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                {index + 1}. {data.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    width: '90%',
                                }}
                            >
                                {data.description}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Typography
                sx={{
                    fontSize: '14px',
                    mt: 3
                }}
            >
                Additionally, you can chat with our AI Trading Advisor for more personalized guidance and support. Simplify your trading decisions and maximize your efficiency with our intuitive AI assistant.
            </Typography>
        </Box>
    );
};

export default LandingPage;

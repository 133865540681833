import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

// Initial state

const initialState = {
    demands: [],

    purchases: [], // Add separate array for purchases

    selectedDemand: null,

    isUploading: false,

    isFetching: false,

    isFetchingSingle: false,

    isDeleting: false,

    lastPurchaseTime: Date.now(), // Add this

    error: null
};

// Upload Demand
export const uploadDemand = createAsyncThunk(
    'demand/uploadDemand',

    async (demandData, thunkAPI) => {
        try {
            const response = await axios.post(
                `/api/import_demand`,

                demandData,

                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            );

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error.response?.data || 'Upload failed'
            );
        }
    }
);

// Get All Demands
export const getAllDemands = createAsyncThunk(
    'demand/getAllDemands',

    async (_, thunkAPI) => {
        try {
            console.log('Fetching all demands');
            const response = await axios.get(`/api/demands`);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error.response?.data || 'Failed to fetch demands'
            );
        }
    }
);

// Get Single Demand
export const getDemand = createAsyncThunk(
    'demand/getDemand',

    async (uuid, thunkAPI) => {
        try {
            console.log('Fetching single demand');
            const response = await axios.get(`/api/demands/${uuid}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error.response?.data || 'Failed to fetch demand'
            );
        }
    }
);

// Save ShoppingList
export const saveShoppingList = createAsyncThunk(
    'demand/saveShoppingList',
    async (shoppingListItem, thunkAPI) => {
        try {
            console.log('Saving shopping list item');
            // Ensure the shopping list item is an object, not an array
            const response = await axios.post(
                `/api/shoppinglist`,
                shoppingListItem, // Send the object directly
                { headers: { 'Content-Type': 'application/json' } }
            );
            console.log("response", response)
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error.response?.data || 'Failed to save shopping list item'
            );
        }
    }
);

//Get Purchases
export const getPurchases = createAsyncThunk(
    'demand/getPurchases',
    async (uuid, thunkAPI) => {
        try {
            const response = await axios.get(
                `/api/shoppinglist_orders?demand_uuid=${uuid}`
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error.response?.data || 'Failed to fetch purchases'
            );
        }
    }
);

// Delete Demand by UUID
export const deleteDemand = createAsyncThunk(
    'demand/deleteDemand',

    async (uuid, thunkAPI) => {
        try {
            const response = await axios.delete(`/api/demands/${uuid}`);

            return uuid;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error.response?.data || 'Delete failed'
            );
        }
    }
);

// Demand Slice
const demandSlice = createSlice({
    name: 'demand',

    initialState,

    reducers: {
        triggerPurchasesRefetch: (state) => {
            state.lastPurchaseTime = Date.now();
        }
    },

    extraReducers: (builder) => {
        builder

            // Upload Demand
            .addCase(uploadDemand.pending, (state) => {
                state.isUploading = true;

                state.error = null;
            })

            .addCase(uploadDemand.fulfilled, (state, action) => {
                state.isUploading = false;

                state.demands.push(action.payload);
            })

            .addCase(uploadDemand.rejected, (state, action) => {
                state.isUploading = false;

                state.error = action.payload;
            })

            // Get All Demands

            .addCase(getAllDemands.pending, (state) => {
                state.isFetching = true;

                state.error = null;
            })

            .addCase(getAllDemands.fulfilled, (state, action) => {
                state.isFetching = false;

                state.demands = action.payload;
            })

            .addCase(getAllDemands.rejected, (state, action) => {
                state.isFetching = false;

                state.error = action.payload;
            })

            // Get Single Demand
            .addCase(getDemand.pending, (state) => {
                state.isFetchingSingle = true;

                state.error = null;
            })

            .addCase(getDemand.fulfilled, (state, action) => {
                state.isFetchingSingle = false;

                state.selectedDemand = action.payload; // Store the fetched single demand
            })

            .addCase(getDemand.rejected, (state, action) => {
                state.isFetchingSingle = false;

                state.error = action.payload;
            })

            .addCase(saveShoppingList.pending, (state) => {
                state.isUploading = true;
                state.error = null;
            })
            .addCase(saveShoppingList.fulfilled, (state, action) => {
                state.isUploading = false;

                // Here you want to add the shopping list item
                // If the shopping list item already exists, update it
                const existingIndex = state.demands.findIndex(
                    (demand) => demand.uuid === action.payload.uuid
                );
                if (existingIndex >= 0) {
                    // Update the existing demand item in the array
                    state.demands[existingIndex] = {
                        ...state.demands[existingIndex],
                        ...action.payload
                    };
                } else {
                    // Otherwise, add it to the list
                    state.demands.push(action.payload);
                }
            })
            .addCase(saveShoppingList.rejected, (state, action) => {
                state.isUploading = false;
                state.error = action.payload;
            })
            .addCase(getPurchases.pending, (state) => {
                state.isFetching = true;
                state.error = null;
            })
            .addCase(getPurchases.fulfilled, (state, action) => {
                state.isFetching = false;
                state.purchases = action.payload; // Store in purchases, not demands
            })
            .addCase(getPurchases.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.payload;
            })

            // Delete Demand
            .addCase(deleteDemand.pending, (state) => {
                state.isDeleting = true;

                state.error = null;
            })

            .addCase(deleteDemand.fulfilled, (state, action) => {
                state.isDeleting = false;

                // Correct filtering to remove the deleted demand

                state.demands = state.demands.filter(
                    (demand) => demand.uuid !== action.payload
                );
            })

            .addCase(deleteDemand.rejected, (state, action) => {
                state.isDeleting = false;

                state.error = action.payload;
            });
    }
});

export const { triggerPurchasesRefetch } = demandSlice.actions;

export default demandSlice.reducer;

import React, { useMemo, useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Paper,
    Modal,
    Snackbar,
    Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactTable } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import {
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    deleteDemand,
    getDemand
} from '@store/feature/tradingadvisor/tradingAdvisorSlice';
import { GAS_PATH } from '@utils';

// New styles or inline sx for modal content
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: '4px',
    boxShadow: 24
};

const GasDemandList = ({ demands, isFetching }) => {
    console.log('🚀 ~ GasDemandList ~ demands:', demands);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // New state for Delete Modal
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    // New state for Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleDeleteDemand = (uuid) => {
        dispatch(deleteDemand(uuid));
        setDeleteModalOpen(false);
    };

    const columns = useMemo(
        () => [
            {
                header: 'Demand Name',
                id: 'demand_name',
                accessorFn: ({ demand_name }) => demand_name || '',
                Cell: ({ row }) => (
                    <Typography
                        sx={{
                            color: '#27A9FF',
                            cursor: 'pointer',
                            textAlign: 'center',
                            paddingX: '8px',
                            fontWeight: 'bold'
                        }}
                        onClick={() =>
                            handleCellClick(
                                row.original.uuid,
                                row.original.product
                            )
                        }
                    >
                        {row.original.demand_name}
                    </Typography>
                )
            },
            {
                header: 'Product',
                id: 'product',
                accessorFn: ({ product }) => product || ''
            },
            {
                header: 'Total Volume (MWh)',
                id: 'total_volume',
                accessorFn: ({ total_volume }) => total_volume || ''
            },
            {
                header: 'Actions',
                id: 'actions',
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 1
                        }}
                    >
                        <Button
                            onClick={() => {
                                setDeleteId(row.original.uuid);
                                setDeleteModalOpen(true);
                            }}
                            color="error"
                        >
                            <DeleteIcon />
                        </Button>
                    </Box>
                ),
                size: 100
            }
        ],
        []
    );

    const handleCellClick = (uuid, productType) => {
        dispatch(getDemand(uuid)).then(() => {
            navigate(`${GAS_PATH.TRADING_ADVISOR}/${uuid}?productType=${productType}
                `);
        });
    };

    return (
        <Paper style={{ height: '100%', width: '100%', overflow: 'auto' }}>
            <ReactTable
                columns={columns}
                data={demands}
                showCellBorders={true}
                renderToolbarInternalActions={({ table }) => (
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                    </div>
                )}
                headerText={'Demand List'}
                isLoading={isFetching}
            />
            {/* Delete Confirmation Modal */}
            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
            >
                <Box sx={modalStyle}>
                    <Typography variant="h6" mb={2}>
                        Confirm Delete
                    </Typography>
                    <Typography mb={3}>
                        Are you sure you want to delete this demand?
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 2
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => setDeleteModalOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleDeleteDemand(deleteId)}
                        >
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    severity="success"
                    onClose={() => setSnackbarOpen(false)}
                >
                    {/* {demand_name} Demand successfully deleted  */}
                    {demands.demand_name} Demand successfully deleted
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default GasDemandList;

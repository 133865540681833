import React, { useEffect, useMemo } from 'react';
import { ReactTable } from '@components';
import {
    MRT_ToggleGlobalFilterButton,
    MRT_ToggleDensePaddingButton
} from 'material-react-table';
import { Box, Modal, Typography } from '@mui/material';
import AddEntry from './AddEntry';
import { useDispatch, useSelector } from 'react-redux';
import { apiGetOrder } from '@store';
import { exportTableToCSV } from '@utils/ordersCsvExport';
import DownloadIcon from '@mui/icons-material/Download'; // Or use a similar icon

const OrderLog = ({ openModal, handleCloseModal }) => {
    const dispatch = useDispatch()
    const { order, isGetOrderLoading } = useSelector((state) => state.order)

    useEffect(() => {
        dispatch(apiGetOrder())
    }, [dispatch])

    const handleDownloadCSV = () => {
        exportTableToCSV(columns, order, 'order_log.csv');
    };

    const columns = useMemo(
        () => [
            {
                header: 'Contract',
                id: 'contract',
                accessorFn: ({ contract }) => contract || '',
                Cell: ({ row }) => (
                    <Typography
                        sx={{
                            color: '#3B9D9D',
                            fontSize: 'inherit',
                            fontWeight: 'bold',
                            pl: '8px'
                        }}
                    >
                        {row?.original?.data?.sym}
                    </Typography>
                ),
                size: 50
            },
            {
                header: 'Buyer',
                id: 'buyer',
                accessorFn: ({ buyer }) => buyer || '',
                Cell: ({ row }) => (
                    <Typography
                        sx={{
                            fontSize: 'inherit',
                            color: 'black'
                        }}
                    >
                        {row?.original?.data?.buyer}
                    </Typography>
                ),
                size: 30
            },
            {
                header: 'Seller',
                id: 'seller',
                accessorFn: ({ seller }) => seller || '',
                Cell: ({ row }) => (
                    <Typography
                        sx={{
                            fontSize: 'inherit',
                            color: 'black'
                        }}
                    >
                        {row?.original?.data?.seller}
                    </Typography>
                ),
                size: 30
            },
            {
                header: 'Delivery-Point',
                id: 'deliverypoint',
                accessorFn: ({ deliverypoint }) => deliverypoint || '',
                Cell: ({ row }) => (
                    <Typography
                        sx={{
                            fontSize: 'inherit',
                            color: 'black'
                        }}
                    >
                        {row?.original?.data?.deliverypoint}
                    </Typography>
                ),
                size: 30
            },
            {
                header: 'Volume',
                id: 'volume_str',
                accessorFn: ({ volume_str }) =>
                    volume_str?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },

            {
                header: 'Units',
                id: 'units',
                accessorFn: ({ units }) => units || 'MWh/h',
                Cell: ({ row }) => (
                    <Typography
                        sx={{
                            fontSize: 'default',
                            color: '#27A9FF'
                        }}
                    >
                        MWh/h
                    </Typography>
                ),
                size: 30
            },
            {
                header: 'Price',
                id: 'order_price_str',
                accessorFn: ({ order_price_str }) =>
                    order_price_str?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ||
                    '',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: 'Currency',
                id: 'currency',
                accessorFn: ({ currency }) => currency || '',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: 'Type',
                id: 'type',
                Cell: ({ row }) => (
                    <>
                        {row?.original?.data?.type === 'sell' ? (
                            <Typography
                                sx={{
                                    fontSize: 'inherit',
                                    color: 'rgba(235, 105, 105, 1)'
                                }}
                            >
                                Sell
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: 'inherit',
                                    color: 'rgba(39, 169, 255, 1)'
                                }}
                            >
                                Buy
                            </Typography>
                        )}
                    </>
                ),
                size: 50
            },
            {
                header: 'Status',
                id: 'order_status',
                accessorFn: ({ order_status }) => order_status || 'Completed',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },

            {
                header: 'Account',
                id: 'username',
                accessorFn: ({ username }) => username || '',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: 'Created-At',
                id: 'created_at',
                accessorFn: ({ created_at }) => created_at || '',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            }
        ],
        []
    );

    return (
        <>
            <Modal open={openModal} onClose={handleCloseModal}>
                <AddEntry handleClose={handleCloseModal} open={openModal} />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={({ table }) => (
                    // <div onMouseDown={(e) => e.stopPropagation()}>
                    //     <MRT_ToggleGlobalFilterButton table={table} />
                    //     {/* <IconButton
                    //         onClick={handleOpenModal}
                    //         title="Create Exchange"
                    //     >
                    //         <AddBoxIcon />
                    //     </IconButton> */}
                    // </div>
                    <Box sx={{ display: 'flex' }}>
                        <Box
                            component="button"
                            onClick={handleDownloadCSV}
                            sx={{
                                cursor: 'pointer',
                                background: 'none',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                p: '0.5rem',
                                '&:hover': { backgroundColor: '#f5f5f5' }
                            }}
                            aria-label="Download CSV"
                        >
                            <DownloadIcon sx={{ color: 'gray' }} />
                        </Box>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                    </Box>
                )}
                headerText={'Order Logs'}
                columns={columns}
                data={order || []}
                isLoading={isGetOrderLoading}
                showCellBorders={true}
            />
        </>
    );
};

export default OrderLog;

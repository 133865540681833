import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography
} from '@mui/material';

const CSVTemplate = ({ isOpen, onClose }) => {
    // Updated CSV content with new data
    const csvContent = `01.01.2026 00:00;1.5
01.01.2026 00:01;2.5
01.01.2026 00:02;3.5
01.01.2026 00:03;4.5
01.01.2026 00:04;5.5
01.01.2026 00:05;6.5
01.01.2026 00:06;7.5
01.01.2026 00:07;8.5
01.01.2026 00:08;9.5
01.01.2026 00:09;10.5`;
    const csvHref =
        'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>CSV Template</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1" gutterBottom>
                    The CSV file should be in the following format:
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>
                    time,value
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Example:
                </Typography>
                <Typography variant="body1" component="div">
                    <pre>{csvContent}</pre>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    component="a"
                    href={csvHref}
                    download="csv_template.csv"
                    color="primary"
                >
                    Download Template
                </Button>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CSVTemplate;

import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShoppingListImg from '../../../../assets/svg/shopping-list.svg';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDemand } from '../../../../store/feature/tradingadvisor/tradingAdvisorSlice';
import HedgingTable from './HedgingTable';
import Error from '../Error';

const ShoppingList = ({ contract }) => {
    const { uuid } = useParams();
    const dispatch = useDispatch();

    // Select demand state from Redux store
    const {selectedDemand} = useSelector((state) => state.demand);
    const { isFetchingSingle } = useSelector((state) => state.demand);
    const error = useSelector((state) => state.demand.error);

  

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '3px'
                }}
            >
                <InfoOutlinedIcon
                    sx={{
                        fontSize: '16px',
                        p: 0,
                        m: 0
                    }}
                />
                <Typography
                    sx={{
                        fontSize: '13px',
                        p: 0,
                        m: 0
                    }}
                >
                    More Info
                </Typography>
            </Box>

            {
            error ? (
                <Error
                    message={error}
                    retry={() => dispatch(getDemand(uuid))}
                    goBack={true}
                />
            ) : selectedDemand?.shopping_list?.length > 0 ? (
                <HedgingTable
                    shoppingList={selectedDemand.shopping_list}
                    productType={selectedDemand.product}
                    contract={contract}
                    isLoading={isFetchingSingle}
                />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 3,
                        width: '100%',
                        padding: '20px',
                        justifyContent: 'center',
                        height: 'calc(100vh - 250px)'
                    }}
                >
                    <img
                        src={ShoppingListImg}
                        alt="Shopping List"
                        style={{
                            width: '200px',
                            height: '200px',
                            objectFit: 'contain'
                        }}
                    />
                    <Typography>
                        This section is currently empty. Go to "Demand" to
                        generate Shopping List.
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default ShoppingList;

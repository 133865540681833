import React, { useState } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import {
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Bar,
    ComposedChart,
    ResponsiveContainer,
    Customized,
} from 'recharts';
import data from './Data.json'; // Import the data.json file

const DemandOutlineCustomized = ({
    width,
    height,
    data,
 
}) => {
    if (!data || !width || !height) return null;

    const barSize = 23;
    const chartMargin = { left: 60, right: 0 };
    const availableWidth = width - chartMargin.left - chartMargin.right;
    const bandWidth = availableWidth / data.length;

    return (
        <g>
            {data.map((entry, index) => {
                // Calculate the bar position to match Recharts' internal positioning
                const barX =
                    chartMargin.left +
                    bandWidth * index +
                    ((bandWidth - barSize) / 2 - 1);
                const maxValue = Math.max(...data.map((d) => d.demand)) + 10;
                const barHeight = (entry.demand / maxValue) * (height - 80); // Adjust for top/bottom margins
                const barY = height - barHeight - 40; // Adjust for bottom margin

                return (
                    <g key={index}>
                        <rect
                            x={barX}
                            y={barY}
                            width={barSize}
                            height={barHeight}
                            fill="none"
                            stroke="black"
                            strokeDasharray="2 2"
                            strokeWidth={1}
                        />
                    </g>
                );
            })}
        </g>
    );
};

const Graph = () => {
    const [showLineChart, setShowLineChart] = useState(true);
    const [showBarChart, setShowBarChart] = useState(true);

    const transformedData = data.demandData.map((demand, index) => ({
        name: `${demand[0]}`,
        demand: demand[1],
        boughtQ: data.boughtData.Q[index][1],
        boughtYear: data.boughtData.Year[index][1],
        boughtMonth: data.boughtData.Month[index][1],
        boughtCal: data.boughtData.Cal[index][1], // Bought Cal value
        suggestedCal: data.suggestedData.Cal[index][1], // Suggested Cal value
        suggestedQ: data.suggestedData.Q[index][1], // Suggested Q value
        suggestedYear: data.suggestedData.Year[index][1], // Suggested Year value
        suggestedMonth: data.suggestedData.Month[index][1] // Suggested Month value
    }));

    // const formatTooltip = (value, name) => {
    //     if (name.includes('bought') || name.includes('suggested')) {
    //         return [`${value}`, name];
    //     }
    //     return [`${value} MWh/h`, name];
    // };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const dataPoint = payload[0].payload;
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: '#fff',
                        padding: '10px',
                        border: '1px solid #ccc'
                    }}
                >
                    <p className="label">{`Month: ${label}`}</p>
                    {payload.map((item) => (
                        <p key={item.dataKey} style={{ color: item.color }}>
                            {`${item.dataKey}: ${item.value}`}
                        </p>
                    ))}
                    {/* Render suggested info even though its series isn't drawn */}
                    <p
                        style={{ color: 'gray' }}
                    >{`Suggested Year: ${dataPoint.suggestedYear}`}</p>
                    <p
                        style={{ color: 'gray' }}
                    >{`Suggested Month: ${dataPoint.suggestedMonth}`}</p>
                    <p
                        style={{ color: 'gray' }}
                    >{`Suggested Q: ${dataPoint.suggestedQ}`}</p>
                    <p
                        style={{ color: 'gray' }}
                    >{`Suggested Cal: ${dataPoint.suggestedCal}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <Card className="w-full">
            <CardHeader title="Energy Demand and Supply Overview" />

            <CardContent>
                <div className="w-full h-[480px]">
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            data={transformedData}
                            margin={{
                                top: 10,
                                // right: 30,
                                // left: 30,
                                bottom: 10
                            }}
                        >
                            <CartesianGrid
                                strokeDasharray="4 4"
                                vertical={true}
                                className="fill-gray-100"
                            />
                            <XAxis
                                dataKey="name"
                                axisLine={true}
                                tickLine={true}
                                label={{
                                    value: 'Month',
                                    position: 'insideBottom',
                                    offset: -10,
                                    fill: 'black',
                                    fontSize: 15,
                                    fontWeight: 500
                                }}
                            />
                            <YAxis
                                axisLine={false}
                                tickLine={false}
                                domain={[0, 'dataMax+10']}
                                tickCount={12}
                                label={{
                                    value: '(MWh/h)',
                                    angle: -90,
                                    position: 'insideLeft',
                                    fill: 'black'
                                }}
                            />
                            <Tooltip
                                // formatter={formatTooltip}
                                content={<CustomTooltip />}
                                offset={50}
                                animationEasing="ease-in"
                            />
                            <Legend align="right" verticalAlign="top" />
                            {showBarChart && (
                                <>
                                    <Customized
                                        component={(props) => {
                                            return (
                                                <DemandOutlineCustomized
                                                    {...props}
                                                    data={transformedData}
                                                />
                                            );
                                        }}
                                    />
                                    <Bar
                                        dataKey="boughtQ"
                                        stackId="a"
                                        fill="#4a90e2"
                                        name="Bought Q"
                                        barSize={22}
                                    />
                                    <Bar
                                        dataKey="boughtYear"
                                        stackId="a"
                                        fill="#f39c"
                                        name="Bought Year"
                                        barSize={22}
                                    />
                                    <Bar
                                        dataKey="boughtMonth"
                                        stackId="a"
                                        fill="#95a"
                                        name="Bought Month"
                                        barSize={22}
                                    />
                                    <Bar
                                    dataKey="boughtCal"
                                    stackId="a"
                                    fill="yellow"
                                    name="Bought Cal"
                                    barSize={22}
                                />
                                </>
                            )}
                            {showLineChart && (
                                <Line
                                    type="monotone"
                                    dataKey="demand"
                                    stroke="blue"
                                    dot={{ fill: 'blue', r: 4 }}
                                    name="Demand"
                                    strokeWidth={2}
                                />
                            )}
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

export default Graph;

export const exportPurchasesToCSV = (columns, data, filename = 'purchases.csv') => {
  // Get headers from columns - capitalize and clean up headers for better readability
  const headers = columns
    .map(col => {
      // Get header text and capitalize first letter
      let header = col.header || col.accessorKey?.split('.').pop() || '';
      return header.charAt(0).toUpperCase() + header.slice(1);
    })
    .join(',');

  // Process rows with proper data extraction for purchases specifically
  const rows = data.map(row => {
    return columns
      .map(col => {
        let value = '';
        
        // Handle nested accessorKey paths
        if (col.accessorKey) {
          const keys = col.accessorKey.split('.');
          let current = {...row};
          for (const key of keys) {
            if (current && current[key] !== undefined) {
              current = current[key];
              value = current;
            } else {
              break;
            }
          }
        }
        
        // Special handling for purchase-specific fields
        if (col.accessorKey === 'data.sym') {
          value = row?.data?.sym || '';
        }
        else if (col.accessorKey === 'data.buyer') {
          value = row?.data?.buyer || '';
        }
        else if (col.accessorKey === 'data.seller') {
          value = row?.data?.seller || '';
        }
        else if (col.accessorKey === 'data.deliverypoint') {
          value = row?.data?.deliverypoint || '';
        }
        else if (col.accessorKey === 'data.vol') {
          value = row?.data?.vol || row?.data?.volume || '';
        }
        else if (col.accessorKey === 'data.price') {
          // Handle price object specifically
          const price = row?.data?.price;
          if (price) {
            if (typeof price === 'object') {
              // Extract numeric value from price object
              value = price.value || price.ask || price.bid || '';
            } else {
              value = price;
            }
          }
        }
        // Add these new fields
        else if (col.accessorKey === 'data.units') {
          value = row?.data?.units || 'MWh/h'; // Default to MWh/h if not specified
        }
        else if (col.accessorKey === 'data.currency') {
          value = row?.data?.currency || 'EUR'; // Default to EUR if not specified
        }
        else if (col.accessorKey === 'data.type') {
          value = row?.data?.type || '';
        }
        else if (col.accessorKey === 'data.status') {
          value = row?.data?.status || 'Completed'; // Default to Completed if not specified
        }
        else if (col.accessorKey === 'username') {
          value = row?.username || '';
        }
        else if (col.accessorKey === 'created_at') {
          value = row?.created_at || '';
        }

        // Format for CSV
        if (value === null || value === undefined) value = '';
        
        // Handle objects (avoid [object Object])
        if (typeof value === 'object') {
          try {
            value = JSON.stringify(value);
          } catch (e) {
            value = '';
          }
        }
        
        // Format strings
        if (typeof value === 'string') {
          // Replace quotes with double quotes (CSV escaping)
          value = value.replace(/"/g, '""');
          // If value contains commas or quotes, wrap in quotes
          if (value.includes(',') || value.includes('"') || value.includes('\n')) {
            value = `"${value}"`;
          }
        }
        
        return value;
      })
      .join(',');
  }).join('\n');

  // Create and download the CSV
  const csvContent = `${headers}\n${rows}`;
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
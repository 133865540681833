import React from 'react';
import { Card, CardContent, CardHeader, Typography, IconButton } from '@mui/material';
import { Button } from '@mui/material';
import { X } from 'lucide-react';

const InfoModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
      onClick={onClose} // Added onClick for background
    >
      <Card 
        className="w-[800px] max-h-[90vh] overflow-auto bg-white" 
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        <CardHeader
          title={<Typography variant="h6">Info</Typography>}
          action={
            <IconButton onClick={onClose}>
              <X className="h-4 w-4" />
            </IconButton>
          }
        />
        <CardContent>
          <div className="space-y-4 text-md text-gray-600">
            <p>
              In the section "Trading Advisor" you can upload your demand forecast for the upcoming years.
            </p>
            <p>
              Our AI tool will use this input to generate the type of contracts that aligns with your demand.
            </p>
            <p>
              Once the products are generated (so called "Shopping List"), you will be presented with a table
              and chart for easy visualization. The shopping list will display all the contracts and their
              corresponding volumes (usually split into multiple hedging periods) that you should purchase
              to achieve the best possible price. You have the flexibility to customize the parameters for each
              contract generated should you wish to follow a different strategy. At the same time, we will
              calculate your exposure and risk of every single product continuously.
            </p>
            <p>
              In the Overview section, you will find a summary of all the contracts you have purchased and
              how many are still needed to reach your target demand. This will help you keep track of your
              progress and make informed decisions.
            </p>
            <p>
              We hope you find this new feature valuable in managing your trading activities.
            </p>
          </div>
          <div className="flex justify-start mt-6 ml-4">
            <Button onClick={onClose} variant="contained" color="primary">
              Close
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default InfoModal;
const GAS = '/gas';
const ELECTRICITY = '/electricity';

export const GAS_PATH = {
    THE_EUR: `${GAS}/the`,
    THE_CHF: `${GAS}/the/chf`,
    THE_PROFILE: `${GAS}/the/profile`,
    THE_CLOSING_PRICE: `${GAS}/the/closing-price`,
    TTF_EUR: `${GAS}/ttf`,
    PEG_EUR: `${GAS}/peg`,
    THE_CONTRACT_DETAILS: `${GAS}/the/contract-details`,
    TTF_CONTRACT_DETAILS: `${GAS}/ttf/contract-details`,
    PEG_CONTRACT_DETAILS: `${GAS}/peg/contract-details`,
    MARKET_PLACE: `${GAS}/market-place`,
    INTRADAY: `${GAS}/intraday`,
    FX: `${GAS}/fx`,
    TRADING_ADVISOR: `${GAS}/trading-advisor`,
    CALENDAR: `${GAS}/calendar`,
    ORDERS: `${GAS}/orders`,
    WATCHLIST: `${GAS}/watchlist`,
    OVERVIEW: `${GAS}/overview`,
    MARKET: `${GAS}/market`,
    NEWS_FEED: `${GAS}/news-feed`,
    CHAT: `${GAS}/chat`,
    CHAT_LOGIN: `${GAS}/chat/login`,
    PRICE_PROFILING: `${GAS}/profiling`,
    SINGLE_PRICE_PROFILE: `${GAS}/profiling`,
    HELP: `${GAS}/help`,
    SETTINGS: `${GAS}/settings`,
    USER_MANAGEMENT: `${GAS}/user-management`,
    EXPORT_USER: `${GAS}/export-user`,
    NOTIFICATIONS: `${GAS}/notifications`,
    PROFILE: `${GAS}/profile`,
    CHANGE_PASSWORD: `${GAS}/change-password`,
    MANAGE_ACCOUNT: `${GAS}/manage-account`,
    HEDGING_MARGIN: `${GAS}/hedging-margin`,
}

export const ELECTRICITY_PATH = {
    CALENDAR: `${ELECTRICITY}/calendar`,
    NEWS_FEED: `${ELECTRICITY}/news-feed`,
    CHE_EUR: `${ELECTRICITY}/che-eur`,
    CH_CHF: `${ELECTRICITY}/ch-chf`,
    ORDERS: `${ELECTRICITY}/orders`,
    DISCLAIMER: `${ELECTRICITY}/disclaimer`,
    HELP: `${ELECTRICITY}/help`,
    PROFILE: `${ELECTRICITY}/profile`,
    CHANGE_PASSWORD: `${ELECTRICITY}/change-password`,
    MANAGE_ACCOUNT: `${ELECTRICITY}/manage-account`,
    SETTINGS: `${ELECTRICITY}/settings`,
    USER_MANAGEMENT: `${ELECTRICITY}/user-management`,
    EXPORT_USER: `${ELECTRICITY}/export-user`,
    CHAT: `${ELECTRICITY}/chat`,
    CHAT_LOGIN: `${ELECTRICITY}/chat/login`,
    HEDGING_MARGIN: `${ELECTRICITY}/hedging-margin`,
}

export const COMMON_PATH = {
    HOME: '/',
    LANDINGPAGE: '/login',
    PROFILE: '/profile',
    UPDATE_PASSWORD: '/update_password',
    CHANGE_PASSWORD: '/change-password',
    USER_MANAGEMENT: '/user-management',
    HEDGING_MARGIN: '/hedging-margin',
    SETTINGS: '/settings',
    CHAT: '/chat',
    CHAT_LOGIN: '/login/chat',
    LOGOUT: '/logout',
}